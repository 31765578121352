import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';

import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { Constants } from 'src/app/others/constants';
import { TransitionService } from 'src/app/services/transition.service';

import { environment } from 'src/environments/environment';
import { DatosContactoComponent } from '../../datos-contacto/datos-contacto.component';

@Component({
  selector: 'app-tu-plan',
  templateUrl: 'tu-plan.component.html',
  styleUrls: ['tu-plan.component.scss']
})
export class TuPlanComponent extends StepComponentAbstract {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;
  constructor(
    private _customLogger: CustomNGXLoggerService,
    public dialogC: MatDialog,
    public transitionService: TransitionService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[TuPlanComponent] constructor');
  }

  get tuPlanVisible(): boolean {
    return this.transitionService.flags.isMostrarTeLlamamos && this.transitionService.flags.isCargaWallmeric;
  }

  isValid(): boolean {
    return true;
  }

  openDatosContacto() {
    if (this.transitionService.flags.isAbrirModalContacto) {
      const data = Constants.TITLE_CONTACTO_ME_INTERESA;
      this.dialogC.open(DatosContactoComponent, {
        maxWidth: Constants.WIDTH_CONTACTO,
        width: Constants.WIDTH_CONTACTO,
        data
      });
    }
  }

  scrollToBottom(): void {
    this.transitionService.onScrollChanged.next(true);
  }

  scrollToTop(): void {
    this.transitionService.onScrollChanged.next(false);
  }
}
