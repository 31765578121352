import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorMessage } from 'src/app/model/message/error-message';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html'
})
export class BlankComponent implements OnInit {
  constructor(private _route: ActivatedRoute) {}
  ngOnInit() {
    this._route.data.subscribe(data => {
      if (data.forceError) throw new ErrorMessage('Error ict');
    });
  }
}
