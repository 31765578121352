/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { formatInteger } from 'src/app/others/utiles';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { Constants } from 'src/app/others/constants';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';
import { DatosCumplimentados } from '../../model/api/datosCumplimentados';
import { Router } from '@angular/router';
import { MSETarificacion } from 'src/app/model/api/models';
import { MapperService } from 'src/app/services/mapper.service';
import { ApiService } from 'src/app/services/api.service';
import { ErrorMessage } from 'src/app/model/message/error-message';

@Component({
  selector: 'app-modificar-datos',
  templateUrl: './modificar-datos.component.html',
  styleUrls: ['./modificar-datos.component.scss']
})
export class ModificarDatosComponent implements OnInit {
  public readonly APORTACION_DIARIA = 'aportacionDiaria';
  public readonly AHORRO_CUENTA = 'ahorroCuenta';
  public readonly AHORRO_INVERSION = 'ahorroInversion';
  public readonly JUBILACION = 'ahorroJubilacion';
  public readonly AHORRO_INVERSION_CHK = 'ahorroInversionCheck';
  public readonly AHORRO_INVERSION_PCR = 'ahorroInversionPorcentaje';
  public readonly AHORRO_JUBILACION_CHK = 'ahorroJubilacionCheck';
  public readonly AHORRO_JUBILACION_PCR = 'ahorroJubilacionPorcentaje';
  public readonly SEGURIDAD_FAMILIAR_CHK = 'seguridadFamiliarCheck';
  public readonly SEGURIDAD_FAMILIAR_PCR = 'seguridadFamiliarPorcentaje';

  /**
   * Stop count of aportacion diaria component
   */
  private _stopCount: boolean;

  /**
   * Increment  of aportacion diaria component
   */
  private _sleep: number;

  private _inc: number;

  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;
  /**
   * Formulario para la tarificacion
   */
  pricingForm: FormGroup;
  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  modificarDatosForm: FormGroup;

  ahoInvCB: boolean;
  segFamiliarCB: boolean;
  jubilacionCB: boolean;

  dataInput: Map<string, number | boolean>;

  placeHolder: Map<string, string | RegExp>;
  pctChange: number;
  sliderPcr: number[];

  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _fb: FormBuilder,
    private _transitionService: TransitionService,
    private _digitalDataService: DigitalDataService,
    private _translateService: TranslateService,
    private _mapperService: MapperService,
    private _apiService: ApiService,
    private _route: Router
  ) {
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[ModificarDatosComponent] constructor');
  }

  /**
   * Inicializamos el formulario de los objetivos
   *
   * @returns objetivos form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      aportacionDiaria: [],
      ahorroCuenta: [
        Constants.VACIO,
        Validators.compose([Validators.pattern(Constants.PATTERN_EURO), Validators.required])
      ],
      ahorroInversion: [
        Constants.VACIO,
        Validators.compose([Validators.pattern(Constants.PATTERN_EURO), Validators.required])
      ],
      ahorroJubilacion: [
        Constants.VACIO,
        Validators.compose([Validators.pattern(Constants.PATTERN_EURO), Validators.required])
      ],
      ahorroInversionCheck: [Constants.VACIO],
      ahorroInversionPorcentaje: [Constants.VACIO],
      seguridadFamiliarCheck: [Constants.VACIO],
      seguridadFamiliarPorcentaje: [Constants.VACIO],
      ahorroJubilacionCheck: [Constants.VACIO],
      ahorroJubilacionPorcentaje: [Constants.VACIO],
      bindingDual: this._fb.group({ lower: [], upper: [] })
    });
    return form;
  }

  /**
   * Acceso a los controles del proyecto
   */
  get controls(): { [key: string]: AbstractControl } {
    return this.modificarDatosForm.controls;
  }

  /**
   * Gets tarificador data
   */
  get datosTarificacion(): DatosCumplimentados {
    return this._transitionService.datosTarificacion;
  }

  /**
   * Sets tarificador data
   */
  set datosTarificacion(value: DatosCumplimentados) {
    this._transitionService.datosTarificacion = value;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    //inicializamos el formulario de los objetivos
    this.modificarDatosForm = this._createForm();
    this.placeHolder = new Map();
    this.placeHolder[this.AHORRO_CUENTA] = '€';
    this.placeHolder[this.AHORRO_INVERSION] = '€';
    this.placeHolder[this.JUBILACION] = '€';
    this.placeHolder[this.AHORRO_INVERSION_PCR] = '%';
    this.placeHolder[this.SEGURIDAD_FAMILIAR_PCR] = '%';
    this.placeHolder[this.AHORRO_JUBILACION_PCR] = '%';
    this.dataInput = new Map();
    this.sliderPcr = [];

    this._transitionService.onLoadTranslation.subscribe(data => {
      this._logger.debug('[ModificarDatosComponent] onLoadTranslation: ' + data);
      if (data) {
        this._translateService.get('pricing.aportacionDiaria.placeholder').subscribe(str => {
          this.placeHolder[this.APORTACION_DIARIA] = str;
          const pattern =
            '^(([0-9]{1,3}(?:\\.[0-9]{3})*)|([0-9]*))(' + this.placeHolder[this.APORTACION_DIARIA] + ')?$';
          this.controls.aportacionDiaria.setValidators(
            Validators.compose([Validators.required, Validators.pattern(pattern), Validators.min(1)])
          );
          Promise.resolve().then(() => {
            this.controls.aportacionDiaria.setValue(
              formatInteger(this.datosTarificacion.aportacionDiaria) + this.placeHolder[this.APORTACION_DIARIA]
            );
          });
        });
      }
    });
    this._cargarDataInput();
    this._transitionService.page = Constants.PAGE_MODIFICAR;
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
  }

  private _cargarDataInput() {
    this.dataInput[this.APORTACION_DIARIA] = this.datosTarificacion.aportacionDiaria;
    this.dataInput[this.AHORRO_CUENTA] = this.datosTarificacion.ahorroCuentasBancarias;
    this.dataInput[this.AHORRO_INVERSION] = this.datosTarificacion.ahorroInversion;
    this.dataInput[this.JUBILACION] = this.datosTarificacion.ahorroJubilacion;
    this.sliderPcr[0] = this.dataInput[this.AHORRO_INVERSION_PCR] =
      this._transitionService.calculoOut.productoAhorroInversion.pctProducto;
    this.sliderPcr[1] = this.dataInput[this.SEGURIDAD_FAMILIAR_PCR] =
      this._transitionService.calculoOut.productoSeguridadFamiliar.pctProducto;
    this.sliderPcr[2] = this.dataInput[this.AHORRO_JUBILACION_PCR] =
      this._transitionService.calculoOut.productoJubilacion.pctProducto;
    this.dataInput[this.AHORRO_INVERSION_CHK] =
      this._transitionService.calculoOut.productoAhorroInversion.pctProducto > 0;
    this.dataInput[this.SEGURIDAD_FAMILIAR_CHK] =
      this._transitionService.calculoOut.productoSeguridadFamiliar.pctProducto > 0;
    this.dataInput[this.AHORRO_JUBILACION_CHK] = this._transitionService.calculoOut.productoJubilacion.pctProducto > 0;

    Promise.resolve().then(() => this.loadData());
  }

  loadData(): void {
    this.controls.aportacionDiaria.setValue(
      formatInteger(this.datosTarificacion.aportacionDiaria) + this.placeHolder[this.APORTACION_DIARIA]
    );
    this.controls.ahorroCuenta.setValue(
      formatInteger(this.datosTarificacion.ahorroCuentasBancarias) + this.placeHolder[this.AHORRO_CUENTA]
    );
    this.controls.ahorroInversion.setValue(
      formatInteger(this.datosTarificacion.ahorroInversion) + this.placeHolder[this.AHORRO_INVERSION]
    );
    this.controls.ahorroJubilacion.setValue(
      formatInteger(this.datosTarificacion.ahorroJubilacion) + this.placeHolder[this.JUBILACION]
    );
    this.controls.ahorroInversionPorcentaje.setValue(
      formatInteger(this._transitionService.calculoOut.productoAhorroInversion.pctProducto) +
        this.placeHolder[this.AHORRO_INVERSION_PCR]
    );
    this.controls.seguridadFamiliarPorcentaje.setValue(
      formatInteger(this._transitionService.calculoOut.productoSeguridadFamiliar.pctProducto) +
        this.placeHolder[this.SEGURIDAD_FAMILIAR_PCR]
    );
    this.controls.ahorroJubilacionPorcentaje.setValue(
      formatInteger(this._transitionService.calculoOut.productoJubilacion.pctProducto) +
        this.placeHolder[this.AHORRO_JUBILACION_PCR]
    );
    this.controls.ahorroInversionCheck.setValue(
      this._transitionService.calculoOut.productoAhorroInversion.pctProducto > 0
    );
    this.controls.seguridadFamiliarCheck.setValue(
      this._transitionService.calculoOut.productoSeguridadFamiliar.pctProducto > 0
    );
    if (this._transitionService.calculoOut.productoSeguridadFamiliar.bloqueo === true) {
      this.controls.seguridadFamiliarCheck.disable();
      this.controls.seguridadFamiliarPorcentaje.disable();
    }
    this.controls.ahorroJubilacionCheck.setValue(this._transitionService.calculoOut.productoJubilacion.pctProducto > 0);
    if (this._transitionService.calculoOut.productoJubilacion.bloqueo === true) {
      this.controls.ahorroJubilacionCheck.disable();
      this.controls.ahorroJubilacionPorcentaje.disable();
    }
  }

  calculate(): void {
    this._setData();

    this._transitionService.datosTarificacion.recalculado = true;

    this._transitionService.cantidadAhorro =
      this._transitionService.datosTarificacion.ahorroCuentasBancarias +
      this._transitionService.datosTarificacion.ahorroInversion +
      this._transitionService.datosTarificacion.ahorroJubilacion;

    if (this._transitionService.cantidadAhorro === 0) {
      this._transitionService.datosTarificacion.tienesAhorros = false;
    } else {
      this._transitionService.datosTarificacion.tienesAhorros = true;
    }

    const mSEtarificacion: MSETarificacion = {};
    mSEtarificacion.calculoIn = this._transitionService.datosTarificacion;
    mSEtarificacion.oficina = this._transitionService.oficina;
    mSEtarificacion.usuario = this._transitionService.usuario;
    mSEtarificacion.forceFlow = this._transitionService.forceFlow;
    mSEtarificacion.operacion = this._mapperService.mapOperacionServer(this._transitionService.operacion);

    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_CALCULATE);

    this._apiService.postTarificacion(mSEtarificacion).subscribe(
      data => {
        this._transitionService.calculoOut = data.data.calculoOut;
        this._transitionService.onDatosTarificacionChanged.next(true);
        this._transitionService.initialPage = Constants.PAGE_TU_PLAN;
        this._transitionService.page = Constants.PAGE_TU_PLAN;
        this._transitionService.onDatosTarificacionChanged.next(true);
        this._transitionService.flags.isFullHeight = false;
        this._transitionService.onStepChanged.next(true);
        this._route.navigateByUrl(`/calculadora-ahorro/simulador${this._transitionService.urlParam}`);
      },
      error => {
        this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'recalcular');
        this._logger.error('[ModificarDatosComponent] calculate: error del servicio ->', error);
        throw new ErrorMessage(error);
      }
    );
  }

  /**
   * Sets output data
   */
  goBack(): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_BACK);
    this._transitionService.initialPage = Constants.PAGE_TU_PLAN;
    this._transitionService.page = Constants.PAGE_TU_PLAN;
    this._transitionService.flags.isFullHeight = false;
    this._transitionService.onStepChanged.next(true);
    this._route.navigateByUrl(`/calculadora-ahorro/simulador${this._transitionService.urlParam}`);
  }

  /**
   * Modifys successive
   *
   * @param inc
   */
  modifySuccessive(inc: number) {
    this.controls.aportacionDiaria.markAsDirty({ onlySelf: false });
    this._inc = inc;
    this._sleep = 300;
    this._stopCount = false;
    this._timerAcceleration(this);
  }

  /**
   * Timers acceleration
   *
   * @param obj
   */
  private _timerAcceleration(obj: ModificarDatosComponent) {
    setTimeout(function () {
      obj._sleep -= 20;
      obj.dataInput[obj.APORTACION_DIARIA] += obj._inc;
      if (obj.dataInput[obj.APORTACION_DIARIA] < 1) obj.dataInput[obj.APORTACION_DIARIA] = 1;
      if (obj._sleep < 0) obj._sleep = 0;
      if (!obj._stopCount) obj._timerAcceleration(obj);
      obj.formatInput(obj.APORTACION_DIARIA);
    }, obj._sleep);
  }

  /**
   * Stops modify
   */
  stopModify(): void {
    this._stopCount = true;
  }

  unformatInput(control: string) {
    if (this.modificarDatosForm.get(control).valid) {
      let text = '';
      if (this.dataInput[control] !== 0) text = this.dataInput[control].toString();
      this.modificarDatosForm.get(control).setValue(text);
    }
  }

  formatInput(control: string): void {
    const value: string = this.modificarDatosForm.get(control).value;
    if (
      !isNaN(Number(value)) &&
      value !== '' &&
      value.indexOf(',') === -1 &&
      value.indexOf('.') === -1 &&
      value.indexOf('-') === -1
    ) {
      const numerico = Number(this.modificarDatosForm.get(control).value);
      if (
        (control !== this.AHORRO_INVERSION_PCR &&
          control !== this.AHORRO_JUBILACION_PCR &&
          control !== this.SEGURIDAD_FAMILIAR_PCR) ||
        (numerico <= 100 && numerico > 0)
      )
        this.dataInput[control] = Number(this.modificarDatosForm.get(control).value);
    }

    this.modificarDatosForm.get(control).setValue(formatInteger(this.dataInput[control]) + this.placeHolder[control]);
  }
  errorPct() {
    if (
      this.dataInput[this.AHORRO_INVERSION_PCR] +
        this.dataInput[this.SEGURIDAD_FAMILIAR_PCR] +
        this.dataInput[this.AHORRO_JUBILACION_PCR] !==
      100
    )
      return true;
    else {
      this.sliderPcr[0] = this.dataInput[this.AHORRO_INVERSION_PCR];
      this.sliderPcr[1] = this.dataInput[this.SEGURIDAD_FAMILIAR_PCR];
      this.sliderPcr[2] = this.dataInput[this.AHORRO_JUBILACION_PCR];
      return false;
    }
  }
  changeAhorroInversionPorcentaje() {
    this.controls.ahorroInversionPorcentaje.setValue(0 + this.placeHolder[this.AHORRO_INVERSION_PCR]);
    this.dataInput[this.AHORRO_INVERSION_PCR] = 0;
    this.dataInput[this.AHORRO_INVERSION_CHK] = !this.dataInput[this.AHORRO_INVERSION_CHK];
    this.pctChange = undefined;
    if (!this.dataInput[this.AHORRO_INVERSION_CHK]) this.controls.ahorroInversionPorcentaje.disable();
    else this.controls.ahorroInversionPorcentaje.enable();
  }
  changeSeguridadFamiliarPorcentaje() {
    this.controls.seguridadFamiliarPorcentaje.setValue(0 + this.placeHolder[this.AHORRO_INVERSION_PCR]);
    this.dataInput[this.SEGURIDAD_FAMILIAR_PCR] = 0;
    this.dataInput[this.SEGURIDAD_FAMILIAR_CHK] = !this.dataInput[this.SEGURIDAD_FAMILIAR_CHK];
    this.pctChange = undefined;
    if (!this.dataInput[this.SEGURIDAD_FAMILIAR_CHK]) this.controls.seguridadFamiliarPorcentaje.disable();
    else this.controls.seguridadFamiliarPorcentaje.enable();
  }
  changeJubilacionPorcentaje() {
    this.controls.ahorroJubilacionPorcentaje.setValue(0 + this.placeHolder[this.AHORRO_INVERSION_PCR]);
    this.dataInput[this.AHORRO_JUBILACION_PCR] = 0;
    this.dataInput[this.AHORRO_JUBILACION_CHK] = !this.dataInput[this.AHORRO_JUBILACION_CHK];
    this.pctChange = undefined;
    if (!this.dataInput[this.AHORRO_JUBILACION_CHK]) this.controls.ahorroJubilacionPorcentaje.disable();
    else this.controls.ahorroJubilacionPorcentaje.enable();
  }

  private _setData() {
    this._logger.info('[ModificarDatosComponent] SetOutputData');
    this.datosTarificacion.aportacionDiaria = this.dataInput[this.APORTACION_DIARIA];
    this.datosTarificacion.ahorroCuentasBancarias = this.dataInput[this.AHORRO_CUENTA];
    this.datosTarificacion.ahorroInversion = this.dataInput[this.AHORRO_INVERSION];
    this.datosTarificacion.ahorroJubilacion = this.dataInput[this.JUBILACION];
    this.datosTarificacion.pctAhorro = this.dataInput[this.AHORRO_INVERSION_PCR];
    this.datosTarificacion.pctVida = this.dataInput[this.SEGURIDAD_FAMILIAR_PCR];
    this.datosTarificacion.pctJubilacion = this.dataInput[this.AHORRO_JUBILACION_PCR];
  }

  sendDigitalData(): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_RESET);
  }
}
