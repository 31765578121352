import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/others/constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { ValidatorService } from 'src/app/services/validator.service';
import { TransitionService } from 'src/app/services/transition.service';
import { ApiService } from 'src/app/services/api.service';
import { Consent, MSEConsentimiento } from 'src/app/model/api/models';
import { MapperService } from 'src/app/services/mapper.service';
import { DocumentService } from 'src/app/services/document.service';
import { DigitalDataService } from 'src/app/services/digital-data.service';

declare global {
  const Walmeric_leadid: string;
}

@Component({
  selector: 'app-datos-contacto',
  templateUrl: './datos-contacto.component.html',
  styleUrls: ['./datos-contacto.component.scss']
})
export class DatosContactoComponent implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  datosContactoForm: FormGroup;
  header: string;

  //segun su valor muestra un texto o otro
  textoCorto = true;

  contactoTeLlamamos = false;
  contactoDescargaPdf = false;
  contactoMeInteresa = false;
  spinTelefono = false;
  spinEmail = false;

  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DatosContactoComponent>,
    private _validatorService: ValidatorService,
    private _transitionService: TransitionService,
    private _apiService: ApiService,
    private _mapperService: MapperService,
    private _documentService: DocumentService,
    private _digitalDataService: DigitalDataService
  ) {
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[DatosContactoComponent] constructor');
    this.datosContactoForm = this._createForm();
    dialogRef.disableClose = true;
    this.controls.correo.setErrors({ phone: true });
  }
  ngOnInit(): void {
    //inicializamos el formulario de los objetivos
    this._logger.debug('[DatosContactoComponent] ngOnInitdata= ', this.data);
    switch (this.data) {
      case Constants.TITLE_CONTACTO_PDF:
        this.contactoDescargaPdf = true;
        this._transitionService.page = Constants.PAGE_DESCARGA;
        this.header = Constants.TITLE_CONTACTO[Constants.TITLE_CONTACTO_PDF];
        break;
      case Constants.TITLE_CONTACTO_TE_LLAMAMOS:
        this.contactoTeLlamamos = true;
        this.header = Constants.TITLE_CONTACTO[Constants.TITLE_CONTACTO_TE_LLAMAMOS];
        this._transitionService.page = Constants.PAGE_CONTACTO;
        break;
      case Constants.TITLE_CONTACTO_ME_INTERESA:
        this.contactoMeInteresa = true;
        this.header = Constants.TITLE_CONTACTO[Constants.TITLE_CONTACTO_ME_INTERESA];
        this._transitionService.page = Constants.PAGE_CONTACTO;
        break;
    }
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
    this._cargarDatosPreCarga();
    this._mapperService.mapOperacionEstado(this.data, this._transitionService.operacion);
  }

  private _cargarDatosPreCarga() {
    this.controls.nombre.setValue(this._transitionService.usuario.nombreApellidos);
    this.controls.correo.setValue(this._transitionService.usuario.email);
    this.controls.codPostal.setValue(this._transitionService.usuario.direccion.codPostal);
    this.controls.telefono.setValue(this._transitionService.usuario.telMovil);
  }
  /**
   * Inicializamos el formulario de los objetivos
   *
   * @returns objetivos form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      nombre: [Constants.VACIO, Validators.required],
      correo: [Constants.VACIO, Validators.required],
      telefono: [Constants.VACIO, Validators.required],
      codPostal: [Constants.VACIO, Validators.compose([Validators.required, ValidatorService.postalCode])],
      politica: [Constants.VACIO, Validators.required],
      publicidad: []
    });
    return form;
  }

  /**
   * Acceso a los controles del proyecto
   */
  get controls(): { [key: string]: AbstractControl } {
    return this.datosContactoForm.controls;
  }

  closeModal() {
    this._transitionService.usuario.nombreApellidos = this.controls.nombre.value;
    this._transitionService.usuario.email = this.controls.correo.value;
    this._transitionService.usuario.direccion.codPostal = this.controls.codPostal.value;
    this._transitionService.usuario.telMovil = this.controls.telefono.value;
    this._transitionService.usuario.wallmericLeadid = Walmeric_leadid;

    if (this.data === Constants.TITLE_CONTACTO_PDF && !this._transitionService.flags.isOpenContacto) {
      this._documentService.downloadDocument(this._mapperService.mapConsetimiento(this.controls.publicidad.value, environment.consent));
    } else if (this.data === Constants.TITLE_CONTACTO_PDF && this._transitionService.flags.isOpenContacto) {
      this._documentService.downloadDocument(null);
    } else if (!this._transitionService.flags.isOpenContacto) {
      this._sendConsentimiento(this._mapperService.mapConsetimiento(this.controls.publicidad.value, environment.consent));
    }
    this._transitionService.flags.isOpenContacto = true;
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_SEND_CALL_FORM);
    this.dialogRef.close();
  }

  checkNumber(): void {
    this.spinTelefono = true;
    this._validatorService
      .validatePhoneNumber(this.controls.telefono.value, this._transitionService.operacion)
      .subscribe(valido => {
        if (valido !== undefined) {
          if (!valido) {
            this.spinTelefono = false;
            this.controls.telefono.setErrors({ phone: true });
            this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR_VALIDATION, 'telefono');
          } else if (valido) {
            this.spinTelefono = false;
            this.controls.telefono.setErrors(null);
          }
        }
      });
  }

  checkCorreo(): void {
    this.spinEmail = true;
    this._validatorService
      .validateEmail(this.controls.correo.value, this._transitionService.operacion)
      .subscribe((valido: boolean) => {
        if (valido !== undefined) {
          if (!valido) {
            this.controls.correo.setErrors({ email: true });
            this.spinEmail = false;
            this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR_VALIDATION, 'email');
          } else if (valido) {
            this.controls.correo.setErrors(null);
            this.spinEmail = false;
          }
        }
      });
  }

  private _sendConsentimiento(consentimiento: Consent) {
    const mSEConsentimiento: MSEConsentimiento = {};
    mSEConsentimiento.calculoIn = this._transitionService.datosTarificacion;
    mSEConsentimiento.calculoOut = this._transitionService.calculoOut;
    mSEConsentimiento.consentimiento = consentimiento;
    mSEConsentimiento.usuario = this._transitionService.usuario;
    mSEConsentimiento.operacion = this._mapperService.mapOperacionServer(this._transitionService.operacion);
    this._apiService.postConsentimiento(mSEConsentimiento).subscribe(
      data => {
        this._logger.info('[datosContactoComponent] _sendConsentimiento: Envio consentimiento OK ->', data);
      },
      error => {
        this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'consentimiento');
        this._logger.error('[datosContactoComponent] _sendConsentimiento: error del servicio ->', error);
      }
    );
  }

  sendDigitalDataClose(): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_CLOSE);
  }

  sendDigitalDataLeerMas(): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_READ_MORE);
  }
}
