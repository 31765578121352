/**
 * Clase abstracta que debe extender cada uno de los componentes que se corresponden
 * con un paso del fomulario para la pricing/contratación
 */
export abstract class StepComponentAbstract {
  /**
   * Posicion que ocupa el componente en el stepper
   */
  stepIndex = 0;
  /*
   * Funcion invocada para continuar al siguiente paso
   */
  // continue(): boolean {
  //   return true;
  // }
  /*
   * Funcion invocada para verificar si el paso es válido
   */
  abstract isValid(): boolean;
}
