import { Message, TypeMessage } from './message';

/**
 * Specialized error class from the implementation of the Message class
 */
export class ErrorMessage implements Message {
  /**
   * Code of error message
   */
  code: string;
  /**
   * Message of error message
   */
  message: string | undefined;
  /**
   * Type of error message
   */
  type: string;
  /**
   * Exception of error message
   */
  exception: undefined | string;
  /**
   * Timestamp of error message
   */
  timestamp: string;
  /**
   * Stack trace of error message
   */
  stackTrace: string | undefined;

  openModal: boolean;

  /**
   * Creates an instance of error message.
   *
   * @param code
   * @param [message]
   */
  constructor(code: string, message?: string, openModal?: boolean) {
    this.code = code;
    this.message = message;
    this.type = TypeMessage.ERROR;
    this.timestamp = Date();
    this.stackTrace = new Error().stack;
    this.exception = 'CustomException';
    this.openModal = openModal;
  }
}
