import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '../model/message/message';
import { MsgModalComponent } from '../layout/msg-modal/msg.modal.component';
import { Constants } from '../others/constants';
import { ErrorMessage } from '../model/message/error-message';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger, CustomNGXLoggerService } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { DigitalDataService } from './digital-data.service';
import { TransitionService } from './transition.service';
import { Router } from '@angular/router';

/**
 * Injectable. Global error handler service
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  /**
   * Logger  of oferta service
   */
  private _logger: NGXLogger;

  /**
   * Constructor
   *
   * @param _gestionErroresService
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    public dialog: MatDialog,
    private _transitionService: TransitionService,
    private _digitalDataService: DigitalDataService,
    private _route: Router
  ) {
    this._logger = this._customLogger.create(environment.logger.modules!.messages!);
    this._logger.info('[GlobalErrorHandlerService] constructor');
  }

  /**
   * Handles error
   *
   * @param error
   */
  handleError(error: Message | HttpErrorResponse | Error): void {
    const data: Message = this._getMessage(error);
    if ((data.message && data.message.indexOf('Error: NG0100') === -1) || !data.message) {
      //delega al servicio de mensajeria
      this._transitionService.page = Constants.PAGE_ERROR_MODAL;
      this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
      this._transitionService.forceFlow.errorCalculo = false;
      this._transitionService.forceFlow.errorIct = false;
      this._transitionService.forceFlow.errorPDF = false;
      if (data.openModal) {
        this.dialog.open(MsgModalComponent, {
          maxWidth: Constants.WIDTH_CONTACTO,
          width: Constants.WIDTH_CONTACTO,
          data
        });
      } else {
        this._route.navigateByUrl('/calculadora-ahorro/error');
        this._transitionService.page = Constants.PAGE_ERROR;
        this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
        this._transitionService.flags.isFullHeight = true;
        this._transitionService.flags.isFullHeightMobile = true;
        this._transitionService.onStepChanged.next(true);
      }
    }
  }

  /**
   * Obtiene el mensaje en funcion del tipo de mensaje de entrada.
   *
   * @param message Mensaje
   * @returns message
   */
  private _getMessage(message: Error | Message | HttpErrorResponse): Message {
    let newMessage: Message;
    if (message instanceof HttpErrorResponse) {
      // Tratamiento para errores Http
      let exception: string = message.name;
      let code: string = message.status.toString();
      let text: string | undefined;
      //Si viene información del Backend la priorizamos
      if (
        message.error &&
        message.error.errorData &&
        message.error.errorData.length > 0 &&
        message.error.errorData[0].detailErrorInfo
      ) {
        //recuperamos el detalle del Backend
        // eslint-disable-next-line no-new-wrappers
        const detailErrorInfoTXT: string = new String(message.error.errorData[0].detailErrorInfo).toString();
        //Si el parseo JSON funciona indica que proviene de una NSEiException, si no viene de otro error no especificado
        try {
          //convertimos el string-JSON a JSON
          const detailErrorInfoJSON = JSON.parse(detailErrorInfoTXT);
          message.error.errorData[0].detailErrorInfo = detailErrorInfoJSON;
          //Sobreescribimos la informacion por defecto si aplica
          if (detailErrorInfoJSON.exception !== undefined) exception = detailErrorInfoJSON.exception;
          if (detailErrorInfoJSON.code !== undefined) code = detailErrorInfoJSON.code;
          if (detailErrorInfoJSON.message !== undefined) text = detailErrorInfoJSON.message;
        } catch (e) {}
      }
      text = code + ': ' + text;
      //Creamos el error
      newMessage = new ErrorMessage(code, text);
      //informamos otros valores
      newMessage.exception = exception;
      //inluimos todo el error original en el stackTrace
      newMessage.stackTrace = JSON.stringify(message);
      return newMessage;
    } else if (message instanceof Error) {
      //Tratamiento para errores angular.
      //recuperamos la descripción y la traza
      newMessage = new ErrorMessage('-1', message.toLocaleString());
      newMessage.exception = 'UncaughtException';
      newMessage.stackTrace = message.stack;
    } else {
      // Mensaje personalizado.
      newMessage = message;
    }
    //evitar error ciclico en la impresión de JSON
    try {
      //this._logger.error('[MESSAGE SERVICE] showFromHandle: ' + JSON.stringify(message));
      //this._logger.error('[MESSAGE SERVICE] showFromHandle: ' + message);
      console.error(message);
    } catch (e) {}
    return newMessage;
  }
}
