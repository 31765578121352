/**
 * Estructura del Loader
 */
export class LoaderMessage {
  /**
   * Orden
   */
  order = 0;
  /**
   * Mensaje
   */
  message = '';
  /**
   * Estado
   */
  finished?: boolean;
}
