import { Component, Inject } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Message } from 'src/app/model/message/message';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from '../../others/constants';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TransitionService } from 'src/app/services/transition.service';

/**
 * Component MsgModalSnackComponent. list of messages to show.
 */
@Component({
  selector: 'app-msg-modal',
  templateUrl: './msg.modal.html',
  styleUrls: ['./msg.modal.scss']
})
export class MsgModalComponent {
  /**
   * Logger  of vehiculos dialog component
   */
  private _logger: NGXLogger;

  /**
   * Type message of msg modal snack component. Required for HTML
   */
  Constants: typeof Constants;

  /**
   * Creates an instance of msg modal component.
   *
   * @param data
   * @param dialogRef
   * @param _customLogger
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Message,
    public dialogRef: MatDialogRef<MsgModalComponent>,
    private _customLogger: CustomNGXLoggerService,
    public digitalDataService: DigitalDataService,
    private _transitionService: TransitionService
  ) {
    dialogRef.disableClose = true;
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.messages);
    this._logger.info('[MSGMODAL COMPONENT]: constructor');
    this.Constants = Constants;
  }

  close() {
    this._transitionService.page = Constants.PAGE_TU_PLAN;

    this.digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_ACCEPT);
    this.dialogRef.close();
  }
}
