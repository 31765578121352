import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

/**
 * Servicio Interceptor de peticiones HTTP para gestionar la visualizacion de dialogo de Loader.
 * Busca la cabecera especifica del loader en la peticion y si la encuentra abre el dialogo y lo cierra una vez finaliza la peticion.
 */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  /**
   * Logger  of oferta service
   */
  private _logger: NGXLogger;

  /**
   * Creates an instance of loader interceptor.
   *
   * @param _loaderService
   * @param _customLogger
   * @param _environment
   */
  constructor(private _loaderService: LoaderService, private _customLogger: CustomNGXLoggerService) {
    //logger con configuración propia para el modulo de oferta
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[LOADER INTERCEPTOR] constructor');
  }

  /**
   * Devuelve la clave de la petición pasada por parámetro
   *
   * @param req HttpRequest
   * @returns String con una clave unica de peticion.
   */
  getKey(req: HttpRequest<unknown>): string {
    return req.method + '#' + req.urlWithParams + '#' + req.serializeBody();
  }

  /**
   * Metodo que intercepta todas las peticiones HTTP.
   */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Comprueba si viene cabecera de Loader
    const hayLoader =
      req.headers.has(LoaderService._HEADER_LOADER) && req.headers.get(LoaderService._HEADER_LOADER) === 'true';
    if (hayLoader) {
      this._logger.info('[LOADER INTERCEPTOR] Nueva peticion');
      // Informa al servicio para que gestione el loader
      this._loaderService.setLoading(this.getKey(req), true, req.headers.get(LoaderService._MESSAGE_LOADER));
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (hayLoader) {
          this._logger.info('[LOADER INTERCEPTOR] Peticion finalizada');
          // Informa al servicio de la finalización de la peticion.
          this._loaderService.setLoading(this.getKey(req), false);
        }
      })
    );
  }
}
