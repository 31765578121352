import { Component, ElementRef, ViewChild } from '@angular/core';
import { TransitionService } from '../services/transition.service';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss']
})
export class LayoutComponent {
  @ViewChild('scrollMe') private _myScrollContainer: ElementRef;
  computeClass: string;
  constructor(public _transitionService: TransitionService) {
    this._transitionService.onStepChanged.subscribe((data: boolean) => {
      if (data) {
        this._computeClassesDelayed(this);
      }
    });

    this._transitionService.onScrollChanged.subscribe((data: boolean) => {
      if (data !== undefined) {
        let toScroll: number;
        if (data) toScroll = this._myScrollContainer.nativeElement.scrollHeight;
        else toScroll = 0;

        try {
          this._myScrollContainer.nativeElement.scrollTop = toScroll;
        } catch (err) {}
      }
    });
  }

  /**
   * Timers acceleration
   *
   * @param obj
   */
  private _computeClassesDelayed(obj: LayoutComponent) {
    setTimeout(function () {
      obj._computeClasses();
    }, 100);
  }

  private _computeClasses() {
    this.computeClass = '';
    if (this._transitionService.flags.isFullHeightMobile) this.computeClass += ' controlerHeightMobile';
    if (this._transitionService.flags.isFullHeight) this.computeClass += ' controlerHeight';
  }
}
