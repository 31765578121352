import { Component, HostListener, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { DatosContactoComponent } from 'src/app/layout/datos-contacto/datos-contacto.component';
import { Constants } from 'src/app/others/constants';
import { amountJubilacion, formatDecimal } from 'src/app/others/utiles';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DatosCumplimentados } from 'src/app/model/api/datosCumplimentados';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document.service';
import { DigitalDataService } from 'src/app/services/digital-data.service';

@Component({
  selector: 'app-bloque-informacion',
  templateUrl: './bloque-informacion.component.html',
  styleUrls: ['./bloque-informacion.component.scss']
})
export class BloqueInformacionComponent implements OnInit {
  chartTypePie: ChartType = ChartType.PieChart;
  optionsChart: object;
  dataChart;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colNames = ['', 'cantidad', { role: 'tooltip', type: 'string', p: { html: true } }];
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _unsubscribeAll: Subject<any>;

  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  //controla la aparicion del modal de datos de contacto
  pcrRiesgo: number[];
  sliderPcr: number[];
  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _transitionService: TransitionService,
    private _documentService: DocumentService,
    private _digitalDataService: DigitalDataService,
    private _dialogC: MatDialog
  ) {
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[BloqueInformacionComponent] constructor');
    this._transitionService.isFirstPage = true;
    this._unsubscribeAll = new Subject();
    this.dataChart = [['', 100, '']];

    this.optionsChart = undefined;
  }

  sendDigitalData(): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_MODIFY);
  }
  /**
   * Gets tarificador data
   */
  get datosTarificacion(): DatosCumplimentados {
    return this._transitionService.datosTarificacion;
  }

  get aportacionTotal(): number {
    return this._transitionService.cantidadAhorro;
  }

  get ahorroInversionPorcentaje(): number {
    return this._transitionService.calculoOut.productoAhorroInversion.pctProducto;
  }

  get seguridadFamiliarPorcentaje(): number {
    return this._transitionService.calculoOut.productoSeguridadFamiliar.pctProducto;
  }

  get jubilacionPorcentaje(): number {
    return this._transitionService.calculoOut.productoJubilacion.pctProducto;
  }

  get jubilacioArriesgadoSaldo(): number {
    return this._transitionService.calculoOut.productoJubilacion.perfilArriesgado.saldo;
  }

  get importeAhorro(): string {
    let importe = '';

    if (this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo > 0) {
      importe = this.createCustomHTMLContent(
        this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo
      );
    }
    return importe;
  }

  get importeSeguridad(): string {
    let importe = '';

    if (this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal > 0) {
      importe = this.createCustomHTMLContent(this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal);
    }
    return importe;
  }

  get importeJubilacion(): string {
    let importe = '';

    if (this._transitionService.calculoOut.productoJubilacion.perfilArriesgado.saldo > 0) {
      importe = this.createCustomHTMLContent(
        this._transitionService.calculoOut.productoJubilacion.perfilArriesgado.saldo
      );
    }
    return importe;
  }

  get edad(): number {
    return this._transitionService.datosTarificacion.edad;
  }

  ngOnInit(): void {
    this.sliderPcr = [];
    /*El dataChart necesita ser cargado en el OnInit ya que si se toma los datos en funcion del transition
    genera un error porque pinta el chart antes de obtener los datos*/
    this._transitionService.onDatosTarificacionChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: boolean) => {
        if (data) {
          const cuantiaJubilacion = amountJubilacion(this._transitionService);
          this.dataChart = [
            [
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo + '€',
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo,
              this.createCustomHTMLContent(
                this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo
              )
            ],
            [
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal + '€',
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal,
              this.createCustomHTMLContent(this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal)
            ],
            [cuantiaJubilacion + '€', cuantiaJubilacion, this.createCustomHTMLContent(cuantiaJubilacion)]
          ];
          this.sliderPcr[0] = this._transitionService.calculoOut.productoAhorroInversion.pctProducto;
          this.sliderPcr[1] = this._transitionService.calculoOut.productoSeguridadFamiliar.pctProducto;
          this.sliderPcr[2] = this._transitionService.calculoOut.productoJubilacion.pctProducto;
          this._getScreenSize();
        }
      });
  }
  //modifica el texto de los porcentajes al mover el slider
  //cambia la posicion de los puntos

  descargarPdf() {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_DOWNLOAD_PDF);
    if (!this._transitionService.flags.isAbrirModalContacto || this._transitionService.flags.isOpenContacto)
      this._documentService.downloadDocument(null);
    else if (!this._transitionService.flags.isOpenContacto) {
      const data = Constants.TITLE_CONTACTO_PDF;
      this._dialogC.open(DatosContactoComponent, {
        maxWidth: Constants.WIDTH_CONTACTO,
        width: Constants.WIDTH_CONTACTO,
        data
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _getScreenSize(event?: undefined): void {
    this.optionsChart = {
      pieSliceText: 'none',
      tooltip: {
        text: 'none',
        textStyle: { color: '#2d373d', fontSize: 14, bold: true, fontName: 'DM Sans' },
        trigger: 'focus',
        isHtml: true
      },
      pieHole: 0.3,
      backgroundColor: { fill: 'transparent' },
      theme: 'maximized',
      legend: 'none',
      slices: {
        0: { color: '#0ca6b3' },
        1: { color: '#a51783' },
        2: { color: '#f2a105' }
      }
    };
  }

  createCustomHTMLContent(total): string {
    return formatDecimal(total) + '€';
  }
}
