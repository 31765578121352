import { Component } from '@angular/core';
import { Constants } from 'src/app/others/constants';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(private _digitalDataService: DigitalDataService, private _translateService: TranslateService) {}

  sendDigitalData(text: string): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FOOTER, this._translateService.instant(text));
  }
}
