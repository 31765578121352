import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TransitionService } from 'src/app/services/transition.service';

@Injectable({
  providedIn: 'root'
})
export class CheckLoadGuard implements CanActivate {
  constructor(public _transitionService: TransitionService) {}
  canActivate(): boolean {
    return this._transitionService.isFirstPageLoad();
  }
}
