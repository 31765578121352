import { Component, HostListener, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Perfil } from 'src/app/model/api/perfil';
import { amountJubilacion, formatDecimal } from 'src/app/others/utiles';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bloque-ahorro-inversion',
  templateUrl: './bloque-ahorro-inversion.component.html',
  styleUrls: ['./bloque-ahorro-inversion.component.scss']
})
export class BloqueAhorroInversionComponent implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  chartTypePie: ChartType = ChartType.PieChart;
  optionsPieChart: object;
  dataPieChart: (string | number)[][];

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _unsubscribeAll: Subject<any>;

  chartTypeColumn: ChartType = ChartType.ColumnChart;
  optionsColumnChart: object;
  dataColumnChart;
  perfil = 2;
  columnNames = [
    '',
    'Capital inicial',
    { role: 'tooltip', type: 'string', p: { html: true } },
    'Aportaciones',
    { role: 'tooltip', type: 'string', p: { html: true } },
    'Intereses acumulados ',
    { role: 'tooltip', type: 'string', p: { html: true } },
    { role: 'annotation', type: 'string' }
  ];

  private _vAxisMax: number;

  constructor(private _customLogger: CustomNGXLoggerService, private _transitionService: TransitionService) {
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[BloqueAhorroInversionComponent] constructor');
    this._unsubscribeAll = new Subject();
    this._vAxisMax = 1000;
  }

  get txtAportacionTotal(): number {
    return this._transitionService.cantidadAhorro;
  }

  get pctPrudente(): number {
    return this._transitionService.calculoOut.productoAhorroInversion.perfilPrudente.pctEstimado;
  }

  get pctModerado(): number {
    return this._transitionService.calculoOut.productoAhorroInversion.perfilModerado.pctEstimado;
  }

  get pctArriesgado(): number {
    return this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.pctEstimado;
  }

  get capitalInicial(): number {
    return this._transitionService.calculoOut.productoAhorroInversion.capitalInicial;
  }

  ngOnInit(): void {
    /*El dataChart necesita ser cargado en el OnInit ya que si se toma los datos en funcion del transition
    genera un error porque pinta el chart antes de obtener los datos*/
    this._transitionService.onDatosTarificacionChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: boolean) => {
        if (data) {
          const cuantiaJubilacion = amountJubilacion(this._transitionService);
          this.dataPieChart = [
            [
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo + '€',
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo
            ],
            [
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal + '€',
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal
            ],
            [cuantiaJubilacion + '€', cuantiaJubilacion]
          ];
          //this.capitalInicial = this._transitionService.calculoOut.productoAhorroInversion.capitalInicial;

          this._setPerfil(this._getPerfil());
          //establecemos el dato maximo vertical para dejar espacio par alas labels de las columnas
          this._vAxisMax =
            (this.capitalInicial +
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.aportaciones +
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.interesesAcumulado) *
            1.2;
          this._getScreenSize();
        }
      });
  }

  updatePerfil(perfil: number) {
    this.perfil = perfil;
    this._setPerfil(this._getPerfil());
  }

  private _getPerfil() {
    let perfil: Perfil;
    switch (this.perfil) {
      case 0:
        perfil = this._transitionService.calculoOut.productoAhorroInversion.perfilPrudente;
        break;
      case 1:
        perfil = this._transitionService.calculoOut.productoAhorroInversion.perfilModerado;
        break;
      case 2:
        perfil = this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado;
        break;
      default:
        perfil = this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado;
        this.perfil = 2;
    }
    return perfil;
  }

  private _setPerfil(perfil: Perfil) {
    this.dataColumnChart = [
      [
        'Hoy',
        this.capitalInicial,
        this.createCustomHTMLContent(this.capitalInicial),
        0,
        this.createCustomHTMLContent(0),
        0,
        this.createCustomHTMLContent(0),
        formatDecimal(this.capitalInicial) + ' €'
      ],
      [
        '5 años',
        this.capitalInicial,
        this.createCustomHTMLContent(this.capitalInicial),
        perfil.perfil5.aportaciones,
        this.createCustomHTMLContent(perfil.perfil5.aportaciones),
        perfil.perfil5.interesesAcumulado,
        this.createCustomHTMLContent(perfil.perfil5.interesesAcumulado),
        formatDecimal(this.capitalInicial + perfil.perfil5.aportaciones + perfil.perfil5.interesesAcumulado) + ' €'
      ],
      [
        '10 años',
        this.capitalInicial,
        this.createCustomHTMLContent(this.capitalInicial),
        perfil.perfil10.aportaciones,
        this.createCustomHTMLContent(perfil.perfil10.aportaciones),
        perfil.perfil10.interesesAcumulado,
        this.createCustomHTMLContent(perfil.perfil10.interesesAcumulado),
        formatDecimal(this.capitalInicial + perfil.perfil10.aportaciones + perfil.perfil10.interesesAcumulado) + ' €'
      ],
      [
        '15 años',
        this.capitalInicial,
        this.createCustomHTMLContent(this.capitalInicial),
        perfil.perfil15.aportaciones,
        this.createCustomHTMLContent(perfil.perfil15.aportaciones),
        perfil.perfil15.interesesAcumulado,
        this.createCustomHTMLContent(perfil.perfil15.interesesAcumulado),
        formatDecimal(this.capitalInicial + perfil.perfil15.aportaciones + perfil.perfil15.interesesAcumulado) + ' €'
      ],
      [
        '20 años',
        this.capitalInicial,
        this.createCustomHTMLContent(this.capitalInicial),
        perfil.perfil20.aportaciones,
        this.createCustomHTMLContent(perfil.perfil20.aportaciones),
        perfil.perfil20.interesesAcumulado,
        this.createCustomHTMLContent(perfil.perfil20.interesesAcumulado),
        formatDecimal(this.capitalInicial + perfil.perfil20.aportaciones + perfil.perfil20.interesesAcumulado) + ' €'
      ]
    ];
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _getScreenSize(event?: undefined): void {
    this.optionsPieChart = {
      pieSliceText: 'none',
      tooltip: { trigger: 'none' },
      pieHole: 0.5,
      legend: 'none',
      backgroundColor: { fill: 'transparent' },
      chartArea: { width: '100%', height: '75%' },
      slices: {
        0: { color: '#0ca6b3' },
        1: { color: '#9cb0bc' },
        2: { color: '#d7dfe4' }
      }
    };
    this.optionsColumnChart = {
      tooltip: { isHtml: true },
      annotations: { alwaysOutside: true, textStyle: { color: '#667985' } },
      displayDateBarSeparator: 'false',
      backgroundColor: { fill: 'transparent' },
      chartArea: { width: '100%', height: '80%' },
      bar: { groupWidth: '50%' },
      isStacked: true,
      colors: ['#cff8fc', '#0fc6d7', '#0ca6b3'],
      vAxis: {
        minorGridlines: { count: 0 },
        gridlines: {
          color: 'none'
        },
        textPosition: 'none',
        baselineColor: '#cccfd2',
        viewWindow: { max: this._vAxisMax }
      },

      hAxis: {
        textStyle: { color: '#2d373d', fontSize: 14, bold: true, fontName: 'DM Sans' }
        //textPosition: 'out',
      }
    };
  }
  createCustomHTMLContent(total): string {
    return formatDecimal(total) + '€';
  }
}
