import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DatosCumplimentados,
  MSEConsentimiento,
  MSECorreo,
  MSEGenerarPDF,
  MSERegistroOperacion,
  MSETarificacion,
  MSETelefono,
  MSSICT,
  MSSTarificacion,
  MSSGenerarPDF
} from '../model/api/models';
import { Constants } from '../others/constants';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    protected httpClient: HttpClient,
    private _translateService: TranslateService,
    private _loaderService: LoaderService
  ) { }

  /**
   * Realiza la consulta a ICT para obtener los datos registrados registrando en el RE si ocurre un error
   *
   * @param idICT Identificador ICT proporcionado por el origen
   */
  public getICT(idICT: string): Observable<{ data: MSSICT }> {
    if (idICT === null || idICT === undefined) {
      throw new Error('Required parameter idICT was null or undefined when calling getICT.');
    }

    const httpHeaders = this._loaderService.getHeaderLoader(
      this._httpHeaders,
      true,
      this._translateService.instant('loader.default')
    );

    return this.httpClient.get<{ data: MSSICT }>(`${Constants.PATH_ICT}/${idICT}`, {
      headers: httpHeaders
    });
  }

  /**
   * Realiza el alta de una operación en el registro de operaciones
   *
   * @param mSERegistroOperacion Objeto con la información necesaria para el RO
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public postRegistroOperacion(mSERegistroOperacion: MSERegistroOperacion) {
    if (mSERegistroOperacion === null || mSERegistroOperacion === undefined) {
      throw new Error(
        'Required parameter mSERegistroOperacion was null or undefined when calling postRegistroOperacion.'
      );
    }

    return this.httpClient.post(
      `${Constants.PATH_RO}`,
      { data: { operacion: mSERegistroOperacion.operacion } },
      {
        headers: this._httpHeaders
      }
    );
  }

  /**
   * Devuelve la tarificación
   *
   * @param mSEtarificacion Objeto con la información necesaria para la tarificación
   */
  public postTarificacion(mSEtarificacion: MSETarificacion): Observable<{ data: MSSTarificacion }> {
    if (mSEtarificacion === null || mSEtarificacion === undefined) {
      throw new Error('Required parameter mSEtarificacion was null or undefined when calling postTarificacion.');
    }

    let calculoInSend: DatosCumplimentados = {};

    calculoInSend = { ...mSEtarificacion.calculoIn };

    calculoInSend.ordenCompraVivienda++;
    calculoInSend.ordenEstudios++;
    calculoInSend.ordenJubilacion++;
    calculoInSend.ordenOtros++;
    calculoInSend.ordenSeguridadFamiliar++;
    calculoInSend.ordenViajesOcio++;

    const httpHeaders = this._loaderService.getHeaderLoader(
      this._httpHeaders,
      true,
      this._translateService.instant('loader.default')
    );

    return this.httpClient.post<{ data: MSSTarificacion }>(
      `${Constants.PATH_TARIFICACION}`,
      {
        data: {
          usuario: mSEtarificacion.usuario,
          oficina: mSEtarificacion.oficina,
          calculoIn: calculoInSend,
          operacion: mSEtarificacion.operacion,
          forceFlow: mSEtarificacion.forceFlow
        }
      }
    );
  }

  /**
   * Devuelve el PDF generado
   *
   * @param mSEGenerarPDF Objeto con la información necesaria para la generación del PDF
   */
  public postGenerarPDF(mSEGenerarPDF: MSEGenerarPDF): Observable<{ data: MSSGenerarPDF }> {
    if (mSEGenerarPDF === null || mSEGenerarPDF === undefined) {
      throw new Error('Required parameter mSEtarificacion was null or undefined when calling postGenerarPDF.');
    }

    let calculoInSend: DatosCumplimentados = {};

    calculoInSend = { ...mSEGenerarPDF.calculoIn };

    calculoInSend.ordenCompraVivienda++;
    calculoInSend.ordenEstudios++;
    calculoInSend.ordenJubilacion++;
    calculoInSend.ordenOtros++;
    calculoInSend.ordenSeguridadFamiliar++;
    calculoInSend.ordenViajesOcio++;

    const httpHeaders = this._loaderService.getHeaderLoader(
      this._httpHeaders,
      true,
      this._translateService.instant('loader.default')
    );

    return this.httpClient.post<{ data: MSSGenerarPDF }>(
      `${Constants.PATH_PDF}`,
      {
        data: {
          calculoIn: calculoInSend,
          calculoOut: mSEGenerarPDF.calculoOut,
          forceFlow: mSEGenerarPDF.forceFlow,
          operacion: mSEGenerarPDF.operacion,
          usuario: mSEGenerarPDF.usuario,
          oficina: mSEGenerarPDF.oficina,
          consentimiento: mSEGenerarPDF.consentimiento
        }
      },
      {
        headers: httpHeaders
      }
    );
  }

  /**
   * Realiza la consulta de un email concreto para detectar algun problema asociado
   *
   * @param validateEmailRequest Realiza la consulta de un email concreto para detectar algun problema asociado
   */
  public postValidateEmail(mSECorreo: MSECorreo) {
    if (mSECorreo === null || mSECorreo === undefined) {
      throw new Error('Required parameter validateEmailRequest was null or undefined when calling postValidateEmail.');
    }

    return this.httpClient.post(
      `${Constants.PATH_VALIDATE_EMAIL}`,
      { data: { correo: mSECorreo.correo, operacion: mSECorreo.operacion } },
      {
        headers: this._httpHeaders
      }
    );
  }

  /**
   * Realiza la consulta de un telefono concreto para detectar algun problema asociado
   *
   * @param validatePhoneRequest Realiza la consulta de un telefono concreto para detectar algun problema asociado
   */

  public postValidatePhone(mSETelefono: MSETelefono) {
    if (mSETelefono === null || mSETelefono === undefined) {
      throw new Error('Required parameter validateEmailRequest was null or undefined when calling postValidatePhone.');
    }

    return this.httpClient.post(
      `${Constants.PATH_VALIDATE_PHONE}`,
      { data: { numeroTelefono: mSETelefono.numeroTelefono, operacion: mSETelefono.operacion } },
      {
        headers: this._httpHeaders
      }
    );
  }

  /**
   * Gestiona la información de contacto y su consentimiento
   *
   * @param mSEContacto Objeto con la información necesaria para la gestion del consentimiento
   */
  public postConsentimiento(mSEConsentimiento: MSEConsentimiento) {
    if (mSEConsentimiento === null || mSEConsentimiento === undefined) {
      throw new Error('Required parameter validateEmailRequest was null or undefined when calling postContacto.');
    }

    let calculoInSend: DatosCumplimentados = {};

    calculoInSend = { ...mSEConsentimiento.calculoIn };

    calculoInSend.ordenCompraVivienda++;
    calculoInSend.ordenEstudios++;
    calculoInSend.ordenJubilacion++;
    calculoInSend.ordenOtros++;
    calculoInSend.ordenSeguridadFamiliar++;
    calculoInSend.ordenViajesOcio++;

    return this.httpClient.post(
      `${Constants.PATH_CONTACTO}`,
      {
        data: {
          usuario: mSEConsentimiento.usuario,
          consentimiento: mSEConsentimiento.consentimiento,
          operacion: mSEConsentimiento.operacion,
          calculoIn: calculoInSend,
          calculoOut: mSEConsentimiento.calculoOut
        }
      },
      {
        headers: this._httpHeaders
      }
    );
  }
}
