import { Injectable } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Constants } from '../others/constants';
import { TransitionService } from './transition.service';

//digital data del etiquetado de google analitics
export interface DigitalData {
  page_title: string;
  CD21_environment: string;
  CD22_anti_robots: string;
  CD24_platform: string;
  CD26_country: string;
  CD28_channel: string;
  CD29_section1: string;
  CD37_url: string;
  CD38_referrer: string;
  CD40_page_type: string;
  CD42_visualization: string;
  CD44_user_agent: string;
  CD49_page_referrer: string;
  CD52_new_repeat: string;
  CD53_client_id: string;
  CD54_user_id?: string;
  CD55_adblock: string;
  CD127_campaign_id?: string;
  CD128_campaign_origin?: string;

  CD131_process_type?: string;
  CD132_process_name?: string;
  CD133_proccess_step_number?: string;
  CD134_process_basic_step?: string;
  CD115_error_type?: string;
  CD116_error_message?: string;
  CD117_error_location?: string;
  CD178_process_progress_id?: number;
  CD140_process_birthdate?: number;
  CD159_daily_money?: number;
  CD165_objectives?: string;
  CD167_savings?: string;
  CD173_process_common_step?: string;
  CD176_product?: string;
  CD177_step_name?: string;
  CD178_progress_id?: number;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
}

declare global {
  interface Window {
    digitalData: DigitalData;
  }
}

declare function stepView(name: string);
declare function popupView(name: string);
declare function formLink(name: string, validation: boolean, incorrectFields: string[]);

@Injectable({
  providedIn: 'root'
})
export class DigitalDataService {
  //indican si el paso se realiza por primera vez
  private _repeatOrNew: Set<string>;
  private _sendfirsIteration = false;
  /**
   * Estructura de datos para la tarificación
   */

  /**
   * Logger  of transition service
   */
  private _logger: NGXLogger;
  private _screenWidth: number;
  /**
   * Creates an instance of pricing service.
   *
   * @param _customLogger
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _transitionService: TransitionService // private _gtmService: GtmService
  ) {
    //logger con configuración propia para el modulo de pricing
    this._logger = this._customLogger.create(environment.logger.services!);
    this._logger.info('[DigitalDataService] constructor');
    this._repeatOrNew = new Set();
    window.digitalData = window.digitalData || undefined;
  }

  get page(): number {
    return this._transitionService.page;
  }

  //rellena el digital data de cada pantalla
  sendDigitalData(event: number, optional?: string, optional2?: string, optional3?: string) {
    let flagStepView = false;
    let flagPopupView = false;
    let flagFormLink = false;
    //preparativos
    const separator = Constants.DIGITAL_DATA_PROCESS_STEP_NUMBER[this.page] === '' ? '' : '-';
    const proccess_step_number =
      Constants.DIGITAL_DATA_PROCESS_STEP_NUMBER[this.page] + separator + Constants.DIGITAL_DATA_STEP_NAME[this.page];

    //pagina
    const newDigitalData: DigitalData = {
      page_title: Constants.DIGITAL_DATA_PAGE_TITLE + proccess_step_number,
      CD21_environment:
        environment.enviroment === 'pro' ? 'production' : environment.enviroment === 'pre' ? 'development' : 'staging',
      CD22_anti_robots: Constants.DIGITAL_DATA_ANTI_ROBOTS,
      CD24_platform: Constants.DIGITAL_DATA_PLATAFORM,
      CD26_country: Constants.DIGITAL_DATA_COUNTRY,
      CD28_channel: Constants.DIGITAL_DATA_CHANNEL,
      CD29_section1: location.pathname,
      CD37_url: window.location.href,
      CD38_referrer: Constants.DIGITAL_DATA_REFERRER,
      CD40_page_type: Constants.DIGITAL_DATA_PAGE_TYPE,
      CD42_visualization: this._screenWidth <= Constants.WIDTH_MOBILE_MAX ? 'responsive' : 'standard',
      CD44_user_agent: window.navigator.userAgent,
      CD49_page_referrer: Constants.DIGITAL_DATA_REFERRER,
      CD52_new_repeat: this._repeatOrNew.has(this.page + '-' + event) ? 'repeat' : 'new',
      CD53_client_id: '',
      CD55_adblock: Constants.DIGITAL_DATA_ADBLOCK
    };
    newDigitalData.CD131_process_type = Constants.DIGITAL_DATA_PROCESS_TYPE;
    newDigitalData.CD132_process_name = Constants.DIGITAL_DATA_PROCESS_NAME;
    newDigitalData.CD133_proccess_step_number = Constants.DIGITAL_DATA_PROCESS_STEP_NUMBER[this.page];
    newDigitalData.CD159_daily_money = this._transitionService.datosTarificacion.aportacionDiaria;
    newDigitalData.CD173_process_common_step = proccess_step_number;
    newDigitalData.CD176_product = Constants.DIGITAL_DATA_PRODUCT;
    newDigitalData.CD177_step_name = Constants.DIGITAL_DATA_STEP_NAME[this.page];
    newDigitalData.CD178_progress_id = this.page;

    if (this.page === Constants.PAGE_TUS_OBJETIVOS)
      newDigitalData.CD134_process_basic_step = Constants.DIGITAL_DATA_PROCESS_BASIC_STEP;

    if (this.page >= Constants.PAGE_TUS_OBJETIVOS) newDigitalData.CD165_objectives = this._txtObjetivos();

    if (this.page === Constants.PAGE_TU_EDAD || Constants.PAGE_TU_PLAN || Constants.PAGE_MODIFICAR)
      newDigitalData.CD167_savings =
        this._transitionService.datosTarificacion.ahorroCuentasBancarias +
        ', ' +
        this._transitionService.datosTarificacion.ahorroInversion +
        ', ' +
        this._transitionService.datosTarificacion.ahorroJubilacion;

    if (this.page >= Constants.PAGE_TU_EDAD)
      newDigitalData.CD140_process_birthdate = this._transitionService.datosTarificacion.edad;

    //eventos
    newDigitalData.eventCategory = 'quote&buy';
    newDigitalData.eventAction = 'savings';
    switch (event) {
      case Constants.DIGITAL_DATA_EVENT_INICIO:
        newDigitalData.eventLabel = 'p1:quoting start';
        break;
      case Constants.DIGITAL_DATA_EVENT_STEP:
        newDigitalData.eventLabel = 'page:' + proccess_step_number;
        if (
          this._transitionService.page === Constants.PAGE_DESCARGA ||
          this._transitionService.page === Constants.PAGE_ERROR_MODAL ||
          this._transitionService.page === Constants.PAGE_CONTACTO
        )
          flagPopupView = true;
        else flagStepView = true;
        break;
      case Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION:
        newDigitalData.eventLabel = 'fist interaction';
        break;
      case Constants.DIGITAL_DATA_EVENT_CALL:
        newDigitalData.eventLabel = 'call me-step1';
        break;
      case Constants.DIGITAL_DATA_EVENT_SEND_CALL_FORM:
        newDigitalData.eventLabel =
          'call me-step2:success | click to call <' + this._transitionService.usuario.telMovil + '>:success';
        flagFormLink = true;
        break;
      case Constants.DIGITAL_DATA_EVENT_BACK:
        newDigitalData.eventLabel = 'volver';
        break;
      case Constants.DIGITAL_DATA_EVENT_CONTINUE:
        newDigitalData.eventLabel = 'continuar';
        break;

      case Constants.DIGITAL_DATA_EVENT_FOOTER:
        newDigitalData.eventCategory = 'footer';
        newDigitalData.eventAction = 'click link';
        newDigitalData.eventLabel = optional;
        break;

      case Constants.DIGITAL_DATA_EVENT_MORE_INFO:
        newDigitalData.eventLabel = 'toolpit';
        break;

      case Constants.DIGITAL_DATA_EVENT_MODIFY:
        newDigitalData.eventLabel = 'modificar datos';
        break;

      case Constants.DIGITAL_DATA_EVENT_SIMULATION:
        newDigitalData.eventLabel = 'p2:price view';
        break;

      case Constants.DIGITAL_DATA_EVENT_DOWNLOAD_PDF:
        newDigitalData.eventCategory = 'download';
        newDigitalData.eventAction = 'pdf';
        newDigitalData.eventLabel = Constants.PDF_TITLE;
        break;

      case Constants.DIGITAL_DATA_EVENT_INTERESTED:
        newDigitalData.eventLabel = 'me interesa';
        break;

      case Constants.DIGITAL_DATA_EVENT_RESET:
        newDigitalData.eventLabel = 'reestablecer';
        break;

      case Constants.DIGITAL_DATA_EVENT_CALCULATE:
        newDigitalData.eventLabel = 'calcular';
        break;

      case Constants.DIGITAL_DATA_EVENT_CLOSE:
        newDigitalData.eventLabel = 'cerrar';
        break;

      case Constants.DIGITAL_DATA_EVENT_OFFICE:
        newDigitalData.eventLabel = 'ir a oficinas ';
        break;

      case Constants.DIGITAL_DATA_EVENT_ACCEPT:
        newDigitalData.eventLabel = 'aceptar';
        break;

      case Constants.DIGITAL_DATA_EVENT_READ_MORE:
        newDigitalData.eventLabel = 'leer mas';
        break;

      case Constants.DIGITAL_DATA_ERROR:
        newDigitalData.CD115_error_type = optional;
        newDigitalData.CD116_error_message = optional2;
        newDigitalData.CD117_error_location = optional3;
        break;
      case Constants.DIGITAL_DATA_ERROR_VALIDATION:
        flagFormLink = true;
        break;
    }

    if (!this._sendfirsIteration || event !== Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION) {
      window.digitalData = newDigitalData;
      if (flagPopupView)
        try {
          popupView(Constants.DIGITAL_DATA_STEP_NAME[this.page]);
        } catch (e) {}
      else if (flagStepView) {
        try {
          stepView(Constants.DIGITAL_DATA_STEP_NAME[this.page]);
        } catch (e) {}
      } else if (flagFormLink) {
        if (event === Constants.DIGITAL_DATA_ERROR_VALIDATION) {
          try {
            formLink(Constants.DIGITAL_DATA_STEP_NAME[this.page], false, [optional]);
          } catch (e) {}
        } else {
          try {
            formLink(Constants.DIGITAL_DATA_STEP_NAME[this.page], true, []);
          } catch (e) {}
        }
      }
    }

    this._repeatOrNew.add(this.page + '-' + event);
    if (event === Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION) this._sendfirsIteration = true;
  }

  private _txtObjetivos(): string {
    const order = ['Prioritarios: ', 'secundarios: ', 'menos importantes: '];
    let txt = '';

    for (let index = 0; index < 3; index++) {
      const min = index * 2;
      const max = (index + 1) * 2;
      txt += order[index];
      if (
        this._transitionService.datosTarificacion.ordenCompraVivienda >= min &&
        this._transitionService.datosTarificacion.ordenCompraVivienda < max
      )
        txt += 'compra vivienda,';
      if (
        this._transitionService.datosTarificacion.ordenEstudios >= min &&
        this._transitionService.datosTarificacion.ordenEstudios < max
      )
        txt += 'estudios,';
      if (
        this._transitionService.datosTarificacion.ordenJubilacion >= min &&
        this._transitionService.datosTarificacion.ordenJubilacion < max
      )
        txt += 'jubilacion,';
      if (
        this._transitionService.datosTarificacion.ordenOtros >= min &&
        this._transitionService.datosTarificacion.ordenOtros < max
      )
        txt += 'otros,';
      if (
        this._transitionService.datosTarificacion.ordenSeguridadFamiliar >= min &&
        this._transitionService.datosTarificacion.ordenSeguridadFamiliar < max
      )
        txt += 'seguridad familiar,';
      if (
        this._transitionService.datosTarificacion.ordenViajesOcio >= min &&
        this._transitionService.datosTarificacion.ordenViajesOcio < max
      )
        txt += 'viajes y ocio,';
    }

    txt = txt.split(',').join(', ');
    txt = txt.substring(0, txt.length - 2);

    return txt;
  }
}
