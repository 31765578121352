import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { LoaderMessage } from 'src/app/model/loader/loaderMessage';
import { environment } from 'src/environments/environment';

/**
 * Componente asociado al dialogo del Loader.
 *
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  /**
   * Logger  of oferta service
   */
  private _logger: NGXLogger;

  /**
   * Creates an instance of loader component.
   *
   * @param data
   * @param _customLogger
   * @param _environment
   */
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      messages: Map<string, LoaderMessage>;
    },
    private _customLogger: CustomNGXLoggerService
  ) {
    //logger con configuración propia para el modulo de oferta
    this._logger = this._customLogger.create(environment.logger.modules!.loader!);
    this._logger.info('[LOADER COMPONENT] constructor');
  }

  ngOnInit(): void {
    this._logger.info('[LOADER COMPONENT] ngOnInit');
  }

  /**
   * Metodo de comparacion personalizado que devuelve 0 es decir, no cambia el orden.
   *
   * @returns order
   */
  insertedOrder(): number {
    return 0;
  }
}
