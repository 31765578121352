import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatosContactoComponent } from 'src/app/layout/datos-contacto/datos-contacto.component';
import { Constants } from 'src/app/others/constants';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TransitionService } from '../../services/transition.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
    public dialogC: MatDialog,
    private _transitionService: TransitionService,
    private _digitalDataService: DigitalDataService
  ) {}

  get isMostrarTeLlamamos(): boolean {
    return this._transitionService.flags.isMostrarTeLlamamos;
  }

  openDatosContacto() {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_CALL);

    const data = Constants.TITLE_CONTACTO_TE_LLAMAMOS;
    this.dialogC.open(DatosContactoComponent, {
      maxWidth: Constants.WIDTH_CONTACTO,
      width: Constants.WIDTH_CONTACTO,
      data
    });
  }
}
