import { Injectable } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Consent, MSEGenerarPDF } from '../model/api/models';
import { Constants } from '../others/constants';
import { ApiService } from './api.service';
import { MapperService } from './mapper.service';
import { TransitionService } from './transition.service';
import { DigitalDataService } from './digital-data.service';
import { ErrorMessage } from '../model/message/error-message';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  /**
   * Logger  of transition service
   */
  private _logger: NGXLogger;

  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _transitionService: TransitionService,
    private _apiService: ApiService,
    private _mapperService: MapperService,
    private _digitalDataService: DigitalDataService
  ) {
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[DocumentService] constructor');
  }

  downloadDocument(consentimiento: Consent) {
    const mSEGenerarPDF: MSEGenerarPDF = {};

    mSEGenerarPDF.calculoIn = this._transitionService.datosTarificacion;
    mSEGenerarPDF.calculoOut = this._transitionService.calculoOut;
    mSEGenerarPDF.forceFlow = this._transitionService.forceFlow;
    mSEGenerarPDF.forceFlow = this._transitionService.forceFlow;
    mSEGenerarPDF.operacion = this._mapperService.mapOperacionServer(this._transitionService.operacion);
    mSEGenerarPDF.usuario = this._transitionService.usuario;
    mSEGenerarPDF.oficina = this._transitionService.oficina;
    mSEGenerarPDF.consentimiento = consentimiento;

    this._apiService.postGenerarPDF(mSEGenerarPDF).subscribe(
      data => {
        const source = `data:application/pdf;base64,${data.data.encodePdf}`;
        const link = document.createElement('a');
        link.href = source;
        link.download = Constants.PDF_TITLE;
        link.click();
      },
      error => {
        this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'generar PDF');
        this._logger.error('[DocumentService] downloadDocument: error del servicio ->', error);
        throw new ErrorMessage(error, '', true);
      }
    );
  }
}
