import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModificarDatosComponent } from './layout/modificar-datos/modificar-datos.component';
import { PricingComponent } from './layout/pricing/pricing.component';
import { CheckLoadGuard } from './guards/check-load.guard';
import { InicioComponent } from './layout/inicio/inicio.component';
import { BlankComponent } from './layout/blank/blank.component';
import { ErrorComponent } from './layout/error/error.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'calculadora-ahorro/simulador',
    pathMatch: 'full'
  },
  { path: 'calculadora-ahorro/error-ict', redirectTo: 'calculadora-ahorro/error' },
  {
    path: 'calculadora-ahorro/modificar',
    component: ModificarDatosComponent,
    canActivate: [CheckLoadGuard]
  },
  {
    path: 'calculadora-ahorro/simulador',
    component: PricingComponent
  },
  {
    path: 'calculadora-ahorro/inicio',
    component: InicioComponent
  },
  {
    path: 'calculadora-ahorro/error',
    component: ErrorComponent
  },
  {
    path: 'calculadora-ahorro/blank',
    component: BlankComponent,
    data: { forceError: false }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
