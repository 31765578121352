import { NgModule } from '@angular/core';
import { PricingComponent } from './pricing.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { TransitionService } from 'src/app/services/transition.service';
import { DynamicStepLoaderComponent } from './dynamic-step-loader/dynamic-step-loader.component';
import { PricingStepDirective } from './pricing-step.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AportacionDiariaComponent } from './aportacion-diaria/aportacion-diaria.component';
import { JubilacionComponent } from './jubilacion/jubilacion.component';
import { AhorroCuentasBancariasComponent } from './ahorro-cuentas-bancarias/ahorro-cuentas-bancarias.component';
import { AhorroInversionComponent } from './ahorro-inversion/ahorro-inversion.component';
import { TienesAhorrosComponent } from './tienes-ahorros/tienes-ahorros.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { IonicModule } from '@ionic/angular';
import { TuEdadComponent } from './tu-edad/tu-edad.component';
import { ModificarDatosComponent } from '../modificar-datos/modificar-datos.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TuPlanComponent } from './tu-plan/tu-plan.component';
import { BloqueInformacionComponent } from './tu-plan/bloque-informacion/bloque-informacion.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { BloqueSeguridadFamiliarComponent } from './tu-plan/bloque-seguridad-familiar/bloque-seguridad-familiar.component';
import { BloqueJubilacionComponent } from './tu-plan/bloque-jubilacion/bloque-jubilacion.component';
import { TooltipsModule } from 'ionic4-tooltips';
import { GoogleChartsModule } from 'angular-google-charts';
import { BloqueAhorroInversionComponent } from './tu-plan/bloque-ahorro-inversion/bloque-ahorro-inversion.component';
import { DatosContactoComponent } from 'src/app/layout/datos-contacto/datos-contacto.component';
import { RouterModule } from '@angular/router';
import { FormatNumberPipe } from 'src/app/pipes/format-number.pipe';

/**
 * Módulo para el proceso de pricing
 */
@NgModule({
  declarations: [
    PricingComponent,
    ObjetivosComponent,
    DynamicStepLoaderComponent,
    PricingStepDirective,
    AportacionDiariaComponent,
    JubilacionComponent,
    AhorroCuentasBancariasComponent,
    AhorroInversionComponent,
    TienesAhorrosComponent,
    TuEdadComponent,
    ModificarDatosComponent,
    TuPlanComponent,
    BloqueInformacionComponent,
    BloqueAhorroInversionComponent,
    BloqueSeguridadFamiliarComponent,
    BloqueJubilacionComponent,
    DatosContactoComponent,
    FormatNumberPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatGridListModule,
    DragDropModule,
    IonicModule,
    MatRadioModule,
    MatSliderModule,
    MatCheckboxModule,
    MatCardModule,
    MatTabsModule,
    MatListModule,
    TooltipsModule,
    GoogleChartsModule,
    RouterModule
  ],
  exports: [PricingComponent],
  providers: [TransitionService]
})
export class PricingModule {}
