import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSSICT } from './model/api/mSSICT';
import { ApiService } from './services/api.service';
import { TransitionService } from './services/transition.service';
import { Constants } from '../app/others/constants';
import { MapperService } from './services/mapper.service';
import { Operacion } from './model/api/operacion';
import { ErrorMessage } from './model/message/error-message';
import { DigitalDataService } from './services/digital-data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;
  private _onAccesoChanged: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  private _onSenIct: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _apiService: ApiService,
    private _mapperService: MapperService,
    private _transitionService: TransitionService,
    public translate: TranslateService,
    private _router: Router,
    private _digitalDataService: DigitalDataService
  ) {
    //logger con configuración propia para el modulo de pricing
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[AppComponent] constructor');
    translate.setDefaultLang(Constants.SPANISH);
    translate.use(Constants.SPANISH).subscribe(() => {
      this._logger.info('[AppComponent] constructor: translation loader');
      this._transitionService.onLoadTranslation.next(true);
    });
  }

  ngOnInit(): void {
    const updateParams = new URLSearchParams(window.location.search);
    this._transitionService.urlParam = '?' + updateParams.toString();

    this._onAccesoChanged.subscribe(data => {
      //Mientras sea undefined hay que mostrar precarga
      if (data !== undefined && data === true) {
        this._router.navigateByUrl(`/calculadora-ahorro/simulador${this._transitionService.urlParam}`);
      } else if (data !== undefined && data === false) {
        this._router.navigateByUrl(`/calculadora-ahorro/blank${this._transitionService.urlParam}`);
      }
    });

    this._verifyAccess(updateParams);
  }

  /**
   * Verificar si el acceso es valido
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _verifyAccess(updateParams: any) {
    let origen = updateParams.get('origen');
    const aportDiaria = updateParams.get('aportDiaria');
    const id_ict = updateParams.get('id_ict');

    if (origen === null) {
      origen = Constants.ORIGEN_MAPFRES;
    }

    this._genForceFlow(updateParams); //ForceFlows
    //Origen ICT
    if (origen === Constants.ORIGEN_PI) {
      if (id_ict !== null) {
        this._sendIct(id_ict);
        this._onSenIct.subscribe(data => {
          if (data !== undefined && data) {
            this._onAccesoChanged.next(true);
            this._transitionService.flags.isMostrarTeLlamamos = false;
            this._transitionService.flags.isAbrirModalContacto = false;
            this._genOperacion(origen);
          } else {
            this._onAccesoChanged.next(false);
          }
        });
      } else throw new ErrorMessage('ict incorrecto');
      //si viene aportacion correcta se salta la primera pantalla
    } else if (
      aportDiaria !== null &&
      !isNaN(Number(aportDiaria)) &&
      Number(aportDiaria) > 0 &&
      Number.isInteger(Number(aportDiaria))
    ) {
      this._transitionService.datosTarificacion.aportacionDiaria = Number(aportDiaria);
      this._transitionService.initialPage = Constants.PAGE_TUS_OBJETIVOS;
      this._transitionService.flags.isMostrarTeLlamamos = true;
      this._transitionService.flags.isAbrirModalContacto = true;
      this._transitionService.flags.isAportacionDiaria = true;
      this._transitionService.flags.isCargaWallmeric = true;
      this._genOperacion(origen);
      this._onAccesoChanged.next(true);
      //sino viene por PI con ict o aportacion incorrecta se va a aportación
    } else {
      this._transitionService.datosTarificacion.aportacionDiaria = 1;
      this._transitionService.initialPage = Constants.PAGE_APORTACION_DIARIA;
      this._transitionService.flags.isMostrarTeLlamamos = true;
      this._transitionService.flags.isAbrirModalContacto = true;
      this._transitionService.flags.isAportacionDiaria = false;
      this._transitionService.flags.isCargaWallmeric = true;
      this._genOperacion(origen);
      this._onAccesoChanged.next(true);
    }
    this._transitionService.page = this._transitionService.initialPage;
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_INICIO);
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
  }

  /**
   * Funcion para verificar si el ICT es correcto.
   */
  private _sendIct(_idIct: string) {
    this._transitionService.flags.isFullHeight = true;
    this._transitionService.flags.isFullHeightMobile = true;
    this._transitionService.onStepChanged.next(true);
    this._apiService.getICT(_idIct).subscribe(
      (output: { data: MSSICT }) => {
        //Validacion ICT
        if (output.data) {
          this._transitionService.oficina = output.data.oficina;
          this._onSenIct.next(true);
        } else {
          this._onSenIct.next(false);
        }
      },
      error => {
        this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'ict');
        this._logger.error('[AppComponent] _sendIct: error del servicio ->', error);
        throw new ErrorMessage(error);
      }
    );
  }

  /**
   * Funcion para la generacion base de las operaciones
   */
  private _genOperacion(origen: string) {
    const operacion: Operacion = this._mapperService.mapOperacion(origen);
    this._transitionService.operacion = operacion;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _genForceFlow(updateParams: any) {
    if (this._transitionService.forceFlow === undefined) {
      this._transitionService.forceFlow = { errorIct: false, errorCalculo: false, errorPDF: false };
    }

    if (updateParams.get('errorIct') === 'true') {
      this._transitionService.forceFlow.errorIct = true;
    }

    if (updateParams.get('errorCalculo') === 'true') {
      this._transitionService.forceFlow.errorCalculo = true;
    }

    if (updateParams.get('errorPDF') === 'true') {
      this._transitionService.forceFlow.errorPDF = true;
    }
  }
}
