import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { Constants } from 'src/app/others/constants';
import { TransitionService } from 'src/app/services/transition.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { formatInteger } from 'src/app/others/utiles';

@Component({
  selector: 'app-tu-edad',
  templateUrl: './tu-edad.component.html',
  styleUrls: ['./tu-edad.component.scss']
})
export class TuEdadComponent extends StepComponentAbstract {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;
  /**
   * Formulario para la tarificacion
   */
  // pricingForm: FormGroup;

  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  edadForm: FormGroup;
  validatorService = ValidatorService;
  private _placeholder: string;
  /**
   * Creates an instance of ahorro inversions bancarias component.
   *
   * @param _customLogger
   * @param _ctrlContainer
   * @param _fb
   * @param _transitionService
   * @param _digitalDataService
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    // private _ctrlContainer: FormGroupDirective,
    private _fb: FormBuilder,
    private _transitionService: TransitionService,
    private _translateService: TranslateService,
    private _validatorService: ValidatorService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[TuEdadComponent] constructor');

    //inicializamos el formulario de los objetivos
    this.edadForm = this._createForm();
    // this.pricingForm = this._ctrlContainer.form;
    // this.pricingForm.addControl('edadForm', this.edadForm);
    this._transitionService.onLoadTranslation.subscribe(data => {
      if (data) {
        this._translateService.get('pricing.tuEdad.placeholder').subscribe(str => {
          this._placeholder = str;
          const pattern = '^[0-9]*(' + this._placeholder + ')?$';
          this.control.setValidators(Validators.compose([Validators.required, Validators.pattern(pattern)]));
          Promise.resolve().then(() => {
            this.control.setValue(formatInteger(this.edad) + this._placeholder);
            this.control.setErrors({ minimumAge: true });
          });
        });
      }
    });
  }
  /**
   * Creates form
   *
   * @returns form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      edad: [Constants.VACIO, Validators.required]
    });
    return form;
  }

  /**
   * Gets tarificador data
   */
  get edad(): number {
    return this._transitionService.datosTarificacion.edad;
  }

  /**
   * Sets tarificador data
   */
  set edad(value: number) {
    this._transitionService.datosTarificacion.edad = value;
  }
  /**
   * Acceso al único control del formulario
   */
  get control(): AbstractControl {
    return this.edadForm.controls.edad;
  }

  isValid(): boolean {
    return this.edadForm.valid || this._transitionService.datosTarificacion.edad > 0;
  }

  /**
   * Unformats input
   */
  unformatInput() {
    let text = '';
    if (this.edad !== 0) text = this.edad.toString();
    this.control.setValue(text);
  }

  /**
   * Formats input
   */
  formatInput() {
    const value: string = this.control.value.replace(/\./g, '');
    if (!isNaN(Number(value)) && value !== '' && value.indexOf(',') === -1) this.edad = Number(value);
    this.control.setValue(formatInteger(this.edad) + this._placeholder);
    this.control.setErrors(this._validatorService.ageRange(this.edad));
  }
}
