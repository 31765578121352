import { Component, OnInit } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';
import { CdkDrag, CdkDragDrop, CdkDropList, copyArrayItem } from '@angular/cdk/drag-drop';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { Constants } from 'src/app/others/constants';

/**
 * Objetivo interface
 */
export interface Objetivo {
  id: number;
  name: string;
  activo: boolean;
  img: string;
}

/**
 * Component
 */
@Component({
  selector: 'app-objetivos',
  templateUrl: './objetivos.component.html',
  styleUrls: ['./objetivos.component.scss']
})
export class ObjetivosComponent extends StepComponentAbstract implements OnInit {
  /**
   * Drag elements
   */
  objetivos: Array<Objetivo[]> = [
    [{ id: 0, name: 'pricing.objetivos.seguridadFamiliar', activo: true, img: 'icon-insurance-life-family' }],
    [{ id: 1, name: 'pricing.objetivos.jubilacion', activo: true, img: 'icon-insurance-life-pension-scheme' }],
    [{ id: 2, name: 'pricing.objetivos.estudios', activo: true, img: 'icon-others-engineering' }],
    [{ id: 3, name: 'pricing.objetivos.compraVivienda', activo: true, img: 'icon-insurance-home-home' }],
    [{ id: 4, name: 'pricing.objetivos.viajesOcio', activo: true, img: 'icon-insurance-travel-plane' }],
    [{ id: 5, name: 'pricing.objetivos.otros', activo: true, img: 'icon-finance-and-justice-saving' }]
  ];

  /**
   * Drop elements
   */
  objetivosSeleccionados: Array<Objetivo[]> = [[], [], [], [], [], []];

  /**
   * Label of column right of objetivos component
   */
  labelRight: string[] = [
    'pricing.objetivos.prioritarios',
    'pricing.objetivos.importantes',
    'pricing.objetivos.menosPreocupantes'
  ];

  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  /**
   * Creates an instance of datos basicos component.
   *
   * @param _route
   * @param _dialog
   * @param _ctrlContainer
   * @param _fb
   * @param _TransitionService
   * @param _customLogger
   * @param _validatorService
   */
  constructor(
    private _transitionService: TransitionService,
    private _digitalDataService: DigitalDataService,
    private _customLogger: CustomNGXLoggerService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
  }

  ngOnInit(): void {
    this._logger.info('[ObjetivosComponent] ngOnInit');
    let index: number;
    for (let i = 0; i < 6; i++) {
      switch (i) {
        case 0:
          index = this._transitionService.datosTarificacion.ordenSeguridadFamiliar;
          break;
        case 1:
          index = this._transitionService.datosTarificacion.ordenJubilacion;
          break;
        case 2:
          index = this._transitionService.datosTarificacion.ordenEstudios;
          break;
        case 3:
          index = this._transitionService.datosTarificacion.ordenCompraVivienda;
          break;
        case 4:
          index = this._transitionService.datosTarificacion.ordenViajesOcio;
          break;
        case 5:
          index = this._transitionService.datosTarificacion.ordenOtros;
      }
      if (index !== undefined && index !== -1) {
        this.objetivos[i][0].activo = false;
        this.objetivosSeleccionados[index] = this.objetivos[i];
      }
    }
  }

  /**
   * Determines whether valid is
   *
   * @returns true if valid
   */
  isValid(): boolean {
    return (
      (this.objetivosSeleccionados[0][0] !== undefined || this.objetivosSeleccionados[1][0] !== undefined) &&
      (this.objetivosSeleccionados[2][0] !== undefined || this.objetivosSeleccionados[3][0] !== undefined) &&
      (this.objetivosSeleccionados[4][0] !== undefined || this.objetivosSeleccionados[5][0] !== undefined)
    );
  }

  /**
   * Sets output data
   */
  setOutputData(): void {
    this._logger.info('[ObjetivosComponent] SetOutputData');
    //buscamos la posicion en la lista de seleccionados mediante la id que es un numero

    this._transitionService.datosTarificacion.ordenSeguridadFamiliar = this.objetivosSeleccionados.findIndex(
      element => element[0] && element[0].id === 0
    );
    this._transitionService.datosTarificacion.ordenJubilacion = this.objetivosSeleccionados.findIndex(
      element => element[0] && element[0].id === 1
    );
    this._transitionService.datosTarificacion.ordenEstudios = this.objetivosSeleccionados.findIndex(
      element => element[0] && element[0].id === 2
    );
    this._transitionService.datosTarificacion.ordenViajesOcio = this.objetivosSeleccionados.findIndex(
      element => element[0] && element[0].id === 4
    );
    this._transitionService.datosTarificacion.ordenCompraVivienda = this.objetivosSeleccionados.findIndex(
      element => element[0] && element[0].id === 3
    );
    this._transitionService.datosTarificacion.ordenOtros = this.objetivosSeleccionados.findIndex(
      element => element[0] && element[0].id === 5
    );
  }

  /**
   * controla que no se puedan meter dos objetivos en la misma lista de objetivos seleccionados
   *
   * @param event
   */
  onDrop(event: CdkDragDrop<Objetivo[]>) {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
    if (event.previousContainer !== event.container && event.container.data.length === 0) {
      event.previousContainer.data[0].activo = false;
      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      //actualizacion en transitionService
      this.setOutputData();
    }
  }

  /**
   * Removes objetivos component
   *
   * @param index
   */
  remove(index: number): void {
    const id = this.objetivosSeleccionados[index][0].id;
    this.objetivos[id][0].activo = true;
    this.objetivosSeleccionados[index] = [];
    //actualizacion en transitionService
    this.setOutputData();
  }

  /**
   * controla que no se previsualice un elemento encima de una lista ya ocupada
   *
   * @param drag
   * @param drop
   * @returns
   */
  dropPredicate(drag: CdkDrag<Objetivo>, drop: CdkDropList<Objetivo[]>) {
    return drop.data.length === 0;
  }
}
