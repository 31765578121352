import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { NGXLogger, CustomNGXLoggerService } from 'ngx-logger';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { Constants } from 'src/app/others/constants';
import { formatInteger } from 'src/app/others/utiles';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ahorro-inversion',
  templateUrl: './ahorro-inversion.component.html',
  styleUrls: ['./ahorro-inversion.component.scss']
})
export class AhorroInversionComponent extends StepComponentAbstract implements OnInit {
  tooltipActive = false;
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;
  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  ahorroInversionForm: FormGroup;
  /**
   * Creates an instance of ahorro inversions bancarias component.
   *
   * @param _customLogger
   * @param _ctrlContainer
   * @param _fb
   * @param _transitionService
   * @param _digitalDataService
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    // private _ctrlContainer: FormGroupDirective,
    private _fb: FormBuilder,
    private _transitionService: TransitionService,
    private _digitalDataService: DigitalDataService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[AhorroInversionComponent] constructor');

    //inicializamos el formulario de los objetivos
    this.ahorroInversionForm = this._createForm();
  }
  /**
   * Creates form
   *
   * @returns form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      ahorroInversion: [
        Constants.VACIO,
        Validators.compose([Validators.pattern(Constants.PATTERN_EURO), Validators.required])
      ]
    });
    return form;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    Promise.resolve().then(() => this.control.setValue(formatInteger(this.ahorroInversion) + '€'));
  }

  /**
   * Gets tarificador data
   */
  get ahorroInversion(): number {
    return this._transitionService.datosTarificacion.ahorroInversion;
  }

  /**
   * Sets tarificador data
   */
  set ahorroInversion(value: number) {
    this._transitionService.datosTarificacion.ahorroInversion = value;
  }
  /**
   * Acceso al único control del formulario
   */
  get control(): AbstractControl {
    return this.ahorroInversionForm.controls.ahorroInversion;
  }

  isValid(): boolean {
    return this.ahorroInversionForm.valid;
  }

  /**
   * Unformats input
   */
  unformatInput() {
    if (this.control.valid) {
      let text = '';
      if (this.ahorroInversion !== 0) text = this.ahorroInversion.toString();
      this.control.setValue(text);
    }
  }

  /**
   * Formats input
   */
  formatInput() {
    const value: string = this.control.value.replace(/\./g, '');
    if (!isNaN(Number(value)) && value !== '' && value.indexOf(',') === -1) {
      this.ahorroInversion = Number(value);
      this.control.setValue(formatInteger(this.ahorroInversion) + '€');
    }
  }
  validateInput() {
    if (this.control.invalid) {
      this.control.setValue(formatInteger(this.ahorroInversion) + '€');
    }
  }

  sendDigitalData(): void {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_MORE_INFO);
  }

  public activateTooltip() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const obj = this;
    obj.tooltipActive = true;
    setTimeout(function () {
      obj.tooltipActive = false;
    }, 3000);
  }
}
