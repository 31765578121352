import { Component, OnInit, HostListener } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Perfil } from 'src/app/model/api/models';
import { amountJubilacion, formatDecimal } from 'src/app/others/utiles';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bloque-jubilacion',
  templateUrl: './bloque-jubilacion.component.html',
  styleUrls: ['./bloque-jubilacion.component.scss']
})
export class BloqueJubilacionComponent implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  chartTypePie: ChartType = ChartType.PieChart;
  optionsChart: object;
  dataChart: (string | number)[][];

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _unsubscribeAll: Subject<any>;

  perfil = 2;
  capitalJubilacion: string;

  constructor(private _customLogger: CustomNGXLoggerService, private _transitionService: TransitionService) {
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[BloqueJubilacionComponent] constructor');
    this._unsubscribeAll = new Subject();
    this.dataChart = [['', 100]];
    this.optionsChart = {
      pieHole: 0.3,
      backgroundColor: { fill: 'transparent' },
      theme: 'maximized',
      legend: 'none',
      slices: {
        0: { color: '#526570' }
      }
    };
  }

  get capitalFinal(): number {
    return this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal;
  }
  get pctPrudente(): number {
    return this._transitionService.calculoOut.productoJubilacion.perfilPrudente.pctEstimado;
  }

  get pctModerado(): number {
    return this._transitionService.calculoOut.productoJubilacion.perfilModerado.pctEstimado;
  }

  get pctArriesgado(): number {
    return this._transitionService.calculoOut.productoJubilacion.perfilArriesgado.pctEstimado;
  }
  visible(): boolean {
    return (
      this._transitionService.calculoOut.productoJubilacion.perfilArriesgado.saldo > 0 &&
      this._transitionService.datosTarificacion.edad < 67
    );
  }
  ngOnInit(): void {
    /*El dataChart necesita ser cargado en el OnInit ya que si se toma los datos en funcion del transition
    genera un error porque pinta el chart antes de obtener los datos*/
    this._transitionService.onDatosTarificacionChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: boolean) => {
        if (data) {
          const cuantiaJubilacion = amountJubilacion(this._transitionService);
          this.dataChart = [
            [
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo + '€',
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo
            ],
            [
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal + '€',
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal
            ],
            [cuantiaJubilacion + '€', cuantiaJubilacion]
          ];

          this._setPerfil(this._getPerfil());
          this._getScreenSize();
        }
      });
  }

  updatePerfil(perfil: number) {
    this.perfil = perfil;
    this._setPerfil(this._getPerfil());
  }

  private _getPerfil(): Perfil {
    let perfil: Perfil;

    switch (this.perfil) {
      case 0:
        perfil = this._transitionService.calculoOut.productoJubilacion.perfilPrudente;
        break;
      case 1:
        perfil = this._transitionService.calculoOut.productoJubilacion.perfilModerado;
        break;
      default:
        perfil = this._transitionService.calculoOut.productoJubilacion.perfilArriesgado;
        this.perfil = 2;
    }
    return perfil;
  }

  private _setPerfil(perfil: Perfil) {
    this.capitalJubilacion = formatDecimal(perfil.saldo);
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _getScreenSize(event?: undefined): void {
    this.optionsChart = {
      tooltip: { trigger: 'none' },
      pieHole: 0.5,
      legend: 'none',
      backgroundColor: { fill: 'transparent' },
      chartArea: { width: '100%', height: '100%' },
      pieSliceText: 'none',
      slices: {
        0: { color: '#9cb0bc' },
        1: { color: '#d7dfe4' },
        2: { color: '#f2a105' }
      }
    };
  }
}
