import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { Constants } from 'src/app/others/constants';
import { formatInteger } from 'src/app/others/utiles';
import { environment } from 'src/environments/environment';
import { TransitionService } from '../../../services/transition.service';

@Component({
  selector: 'app-ahorro-cuentas-bancarias',
  templateUrl: './ahorro-cuentas-bancarias.component.html',
  styleUrls: ['./ahorro-cuentas-bancarias.component.scss']
})
export class AhorroCuentasBancariasComponent extends StepComponentAbstract implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;
  /**
   * Formulario para la tarificacion
   */
  // pricingForm: FormGroup;

  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  ahorroCuentaForm: FormGroup;

  /**
   * Creates an instance of ahorro cuentas bancarias component.
   *
   * @param _customLogger
   * @param _ctrlContainer
   * @param _fb
   * @param _transitionService
   * @param _digitalDataService
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    // private _ctrlContainer: FormGroupDirective,
    private _fb: FormBuilder,
    private _transitionService: TransitionService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[AhorroCuentaBancariaComponent] constructor');

    //inicializamos el formulario de los objetivos
    this.ahorroCuentaForm = this._createForm();
    // this.pricingForm = this._ctrlContainer.form;
    // this.pricingForm.addControl('ahorroCuentaForm', this.ahorroCuentaForm);
  }
  /**
   * Creates form
   *
   * @returns form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      ahorroCuenta: [
        Constants.VACIO,
        Validators.compose([Validators.pattern(Constants.PATTERN_EURO), Validators.required])
      ]
    });
    return form;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    Promise.resolve().then(() => this.control.setValue(formatInteger(this.ahorroCuentasBancarias) + '€'));
  }

  /**
   * Acceso al único control del formulario
   */
  get control(): AbstractControl {
    return this.ahorroCuentaForm.controls.ahorroCuenta;
  }

  /**
   * Gets tarificador data
   */
  get ahorroCuentasBancarias(): number {
    return this._transitionService.datosTarificacion.ahorroCuentasBancarias;
  }

  /**
   * Sets tarificador data
   */
  set ahorroCuentasBancarias(value: number) {
    this._transitionService.datosTarificacion.ahorroCuentasBancarias = value;
  }

  isValid(): boolean {
    return this.ahorroCuentaForm.valid;
  }

  /**
   * Unformats input
   */
  unformatInput() {
    if (this.control.valid) {
      let text = '';
      if (this.ahorroCuentasBancarias !== 0) text = this.ahorroCuentasBancarias.toString();
      this.control.setValue(text);
    }
  }

  /**
   * Formats input
   */
  formatInput() {
    const value: string = this.control.value.replace(/\./g, '');
    if (!isNaN(Number(value)) && value !== '' && value.indexOf(',') === -1) {
      this.ahorroCuentasBancarias = Number(value);
      this.control.setValue(formatInteger(this.ahorroCuentasBancarias) + '€');
    }
  }
  validateInput() {
    if (this.control.invalid) {
      this.control.setValue(formatInteger(this.ahorroCuentasBancarias) + '€');
    }
  }
}
