import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { Constants } from 'src/app/others/constants';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TransitionService } from 'src/app/services/transition.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { formatInteger } from 'src/app/others/utiles';

@Component({
  selector: 'app-aportacion-diaria',
  templateUrl: './aportacion-diaria.component.html',
  styleUrls: ['./aportacion-diaria.component.scss']
})
export class AportacionDiariaComponent extends StepComponentAbstract {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  /**
   * Stop count of aportacion diaria component
   */
  _stopCount: boolean;

  /**
   * Increment  of aportacion diaria component
   */
  private _sleep: number;

  private _inc: number;

  /**
   * Formulario para la tarificacion
   */
  // pricingForm: FormGroup;
  /**
   * Fomulario para la captura y validación de la aportación diaria
   */
  aportacionDiariaForm: FormGroup;

  /**
   * Validator service of aportacion diaria component
   */
  validatorService = ValidatorService;
  private _placeholder: string;
  focus = false;
  /**
   * Creates an instance of aportacion diaria component.
   *
   * @param _customLogger
   * @param _ctrlContainer
   * @param _fb
   * @param _transitionService
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    // private _ctrlContainer: FormGroupDirective,
    private _fb: FormBuilder,
    private _transitionService: TransitionService,
    private _digitalDataService: DigitalDataService,
    private _translateService: TranslateService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[AportacionDiariaComponent] constructor');

    //inicializamos el formulario
    this.aportacionDiariaForm = this._createForm();
    // this.pricingForm = this._ctrlContainer.form;
    // this.pricingForm.addControl('aportacionDiariaForm', this.aportacionDiariaForm);
    this._stopCount = false;
    this._transitionService.onLoadTranslation.subscribe(data => {
      this._logger.debug('[AportacionDiariaComponent] onLoadTranslation: ' + data);
      if (data) {
        this._translateService.get('pricing.aportacionDiaria.placeholder').subscribe(str => {
          this._placeholder = str;
          const pattern = '^(([0-9]{1,3}(?:\\.[0-9]{3})*)|([0-9]*))(' + this._placeholder + ')?$';
          this.control.setValidators(
            Validators.compose([Validators.required, Validators.pattern(pattern), Validators.min(1)])
          );
          Promise.resolve().then(() => {
            this.control.setValue(formatInteger(this.aportacionDiaria) + this._placeholder);
          });
        });
      }
    });
  }

  /**
   * Acceso al único control del formulario
   */
  get control(): AbstractControl {
    return this.aportacionDiariaForm.controls.aportacionDiaria;
  }

  /**
   * Gets tarificador data
   */
  get aportacionDiaria(): number {
    return this._transitionService.datosTarificacion.aportacionDiaria;
  }

  /**
   * Sets tarificador data
   */
  set aportacionDiaria(value: number) {
    this._transitionService.datosTarificacion.aportacionDiaria = value;
  }
  /**
   * Inicializamos el formulario de los objetivos
   *
   * @returns objetivos form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      aportacionDiaria: [Constants.VACIO]
    });
    return form;
  }

  /**
   * Determines whether valid is
   *
   * @returns true if valid
   */
  isValid(): boolean {
    return this.aportacionDiariaForm.valid;
  }

  /**
   * Modifys successive
   *
   * @param inc
   */
  modifySuccessive(inc: number) {
    this._stopCount = false;
    this._inc = inc;
    this._sleep = 300;
    this._timerAcceleration(this);
  }

  /**
   * Timers acceleration
   *
   * @param obj
   */
  private _timerAcceleration(obj: AportacionDiariaComponent) {
    setTimeout(function () {
      obj._sleep -= 20;
      obj.aportacionDiaria += obj._inc;
      if (obj.aportacionDiaria < 1) obj.aportacionDiaria = 1;
      if (obj._sleep < 0) obj._sleep = 0;
      if (!obj._stopCount) obj._timerAcceleration(obj);
      obj.formatInput();
    }, obj._sleep);
  }

  /**
   * Stops modify
   */
  stopModify() {
    this._stopCount = true;
  }

  unformatInput() {
    if (!this.focus) {
      this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
      this.control.setValue(this.aportacionDiaria.toString());
    }
  }

  formatInput() {
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
    const value: string = this.control.value.replace(/\./g, '');
    if (!isNaN(Number(value)) && value !== '' && value.indexOf(',') === -1) this.aportacionDiaria = Number(value);
    this.control.setValue(formatInteger(this.aportacionDiaria) + this._placeholder);
    this.focus = false;
  }
}
