import { TransitionStepperConfig } from 'src/app/model/stepper/transition-stepper-config';
import { AportacionDiariaComponent } from './aportacion-diaria/aportacion-diaria.component';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { TienesAhorrosComponent } from './tienes-ahorros/tienes-ahorros.component';
import { AhorroCuentasBancariasComponent } from './ahorro-cuentas-bancarias/ahorro-cuentas-bancarias.component';
import { JubilacionComponent } from './jubilacion/jubilacion.component';
import { AhorroInversionComponent } from './ahorro-inversion/ahorro-inversion.component';
import { TuEdadComponent } from './tu-edad/tu-edad.component';
import { TuPlanComponent } from './tu-plan/tu-plan.component';

//configuración de los pasos del formulario para la pricing
export const pricingConfMatStep: TransitionStepperConfig[] = [
  { label: 'pricing.aportacionDiaria.title', component: AportacionDiariaComponent },
  { label: 'pricing.objetivos.title', component: ObjetivosComponent },
  { label: 'pricing.tuSituacion', component: TienesAhorrosComponent },
  { label: 'pricing.ahorroCuentasBancarias.title', component: AhorroCuentasBancariasComponent },
  { label: 'pricing.ahorroInversion.title', component: AhorroInversionComponent },
  { label: 'pricing.jubilacion.title', component: JubilacionComponent },
  { label: 'pricing.tuEdad.title', component: TuEdadComponent },
  { label: 'pricing.tuPlan.title', component: TuPlanComponent }
];
