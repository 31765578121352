/**
 * Enumeration for message types
 */
export enum TypeMessage {
  ERROR = 'error',
  WARN = 'warning',
  INFO = 'info'
}

/**
 * Interface to implement the different messages of the application
 */
export interface Message {
  /**
   * Code of message
   */
  code: string | undefined;
  /**
   * Message of message
   */
  message: string | undefined;
  /**
   * Type of message
   */
  type: string;
  /**
   * Exception of message
   */
  exception: string | undefined;
  /**
   * Timestamp of message
   */
  timestamp: string | undefined;
  /**
   * Stack trace of message
   */
  stackTrace: string | undefined;

  openModal: boolean;
}
