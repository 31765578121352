import { Injectable } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { NextStep } from '../model/stepper/next-step';
import { pricingConfMatStep } from '../layout/pricing/pricing-conf-mat-step';
import { StepComponentAbstract } from '../model/stepper/step-component.abstract';
import { DatosCumplimentados } from '../model/api/datosCumplimentados';
import { Oficina } from '../model/api/oficina';
import { Usuario } from '../model/api/usuario';
import { CalculoOut } from '../model/api/calculoOut';
import { Operacion } from '../model/api/operacion';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ForceFlow } from '../model/api/models';
import { Direccion } from '../model/api/direccion';
import { Flags } from '../model/transition/flags';

@Injectable({
  providedIn: 'root'
})
export class TransitionService {
  /**
   * Determines whether load translation on
   */
  onLoadTranslation: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  /**
   * Estructura de datos para la tarificación
   */

  datosTarificacion: DatosCumplimentados;
  calculoOut: CalculoOut;
  direccion: Direccion;
  usuario: Usuario;
  oficina: Oficina;
  operacion: Operacion;
  forceFlow: ForceFlow;
  flags: Flags;
  cantidadAhorro: number;
  onDatosTarificacionChanged: BehaviorSubject<unknown>;
  onStepChanged: BehaviorSubject<true>;
  onScrollChanged: BehaviorSubject<boolean>;

  page: number;
  initialPage: number;

  /**
   * Indice de los pasos del formulario de la tarificación
   */
  private _index: number;

  /**
   * Ultimo step editado
   */
  lastEditedStep: number;

  /**
   * Instancias de los componentes de cada paso del formulario de pricing
   */
  instances: StepComponentAbstract[];

  /**
   * Logger  of transition service
   */
  private _logger: NGXLogger;
  isFirstPage = false;

  /**
   * url param
   */
  urlParam: string;

  /**
   * Creates an instance of pricing service.
   *
   * @param _customLogger
   */
  constructor(private _customLogger: CustomNGXLoggerService, private _router: Router) {
    //logger con configuración propia para el modulo de pricing
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[TransitionService] constructor');
    this._init();
  }

  private _init() {
    this.urlParam = '';

    //inicializamos el indice de los pasos del formulario de pricing
    this._index = 0;
    this.page = 0;
    this.lastEditedStep = -1;
    this.instances = [];
    this.datosTarificacion = {
      aportacionDiaria: 1,
      ahorroCuentasBancarias: 0,
      ahorroInversion: 0,
      ahorroJubilacion: 0,
      edad: 0,
      tienesAhorros: true
    };
    this.calculoOut = {
      productoAhorroInversion: {
        pctProducto: 30,
        perfilPrudente: { pctEstimado: 0 },
        perfilArriesgado: { pctEstimado: 0, perfil20: { saldo: 0 } },
        perfilModerado: { pctEstimado: 0 }
      },
      productoJubilacion: {
        pctProducto: 20,
        perfilPrudente: { pctEstimado: 0 },
        perfilArriesgado: { pctEstimado: 0, saldo: 0 },
        perfilModerado: { pctEstimado: 0 }
      },
      productoSeguridadFamiliar: { pctProducto: 50, capitalFinal: 0 }
    };

    this.cantidadAhorro = 0;

    this.direccion = { codPostal: '' };
    this.usuario = {
      nombreApellidos: '',
      email: '',
      telMovil: '',
      direccion: this.direccion
    };

    this.flags = {
      isOpenContacto: false,
      isMostrarTeLlamamos: true,
      isAbrirModalContacto: true,
      isAportacionDiaria: false,
      isFullHeightMobile: true,
      isFullHeight: true,
      isCargaWallmeric: true
    };

    this.onDatosTarificacionChanged = new BehaviorSubject(undefined);
    this.onStepChanged = new BehaviorSubject(undefined);
    this.onScrollChanged = new BehaviorSubject(undefined);
  }

  reset(): void {
    this._index = 0;
    this.onLoadTranslation.next(true);
  }

  /**
   * Función que devuelve el proximo paso del formulario de pricing
   * Es invocada desde la generación dinámica de los pasos en el
   * componente {@link DynamicStepLoaderComponent}
   *
   * @returns next
   */
  getNext(): NextStep {
    return { component: pricingConfMatStep[this._index].component, index: this._index++ };
  }
  isFirstPageLoad() {
    if (!this.isFirstPage) this._router.navigate(['/calculadora-ahorro/inicio']);
    return this.isFirstPage;
  }
}
