import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { LoaderMessage } from 'src/app/model/loader/loaderMessage';
import { LoaderComponent } from '../layout/loader/loader.component';
import { environment } from 'src/environments/environment';
/**
 * Servicio de gestion de Dialogo Loader.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  /**
   * Mapa de peticiones activas.
   */
  private _mapRequest: Map<string, LoaderMessage>;

  /**
   * Orden asociado a la ultima petición.
   */
  private _order = 0;

  /**
   * Cabecera http para el control de loader
   */
  public static readonly _HEADER_LOADER = 'X-loader-control';

  /**
   * Cabecera http para el mensaje del loader
   */
  public static readonly _MESSAGE_LOADER = 'X-loader-message';

  /**
   * Clave de mensaje por defecto asociado a la peticion.
   */

  private static readonly _DEFAULT_MESSAGE = 'loader.message.default';

  /**
   * Flag de control del loader.
   */
  private _isLoadingBehavior = new BehaviorSubject<boolean>(false);
  isLoading = this._isLoadingBehavior.asObservable();

  /**
   * Referencia al Dialogo.
   */
  dialogRef?: MatDialogRef<LoaderComponent>;

  /**
   * Contador de peticiones pendientes.
   */
  private _requestPending = 0;
  /**
   * Logger  of oferta service
   */
  private _logger: NGXLogger;

  /**
   * Creates an instance of loader service.
   *
   * @param _dialog
   * @param _languageService
   * @param _customLogger
   * @param _environment
   */
  constructor(private _dialog: MatDialog, private _customLogger: CustomNGXLoggerService) {
    //logger con configuración propia para el modulo de oferta
    this._logger = this._customLogger.create(environment.logger.modules!.loader!);
    this._logger.info('[LOADER SERVICE] constructor');
    this._mapRequest = new Map();
  }

  /**
   * Abre el dialogo.
   *
   * @param messages Mapa de peticiones.
   */
  private _open(messages?: Map<string, LoaderMessage> | null): void {
    this.dialogRef = this._dialog.open(LoaderComponent, {
      width: '640px',
      height: '240px',
      disableClose: true,
      data: {
        messages
      }
    });
  }
  /**
   * Gestiona el loader
   *
   * @param requestId Identificador de la peticion
   * @param isLoading true para incluir la request, false para eliminarla.
   * @param message Mensaje asociado a la request
   */
  setLoading(requestId: string, isLoading: boolean, message?: string | null): void {
    this._logger.debug('[LOADER SERVICE] setLoading');
    this._isLoadingBehavior.next(isLoading);
    if (isLoading) {
      // Se genera el mensaje a mostrar.
      this._logger.debug('[LOADER SERVICE] setLoading. Nueva request');
      const messageValue = message !== undefined ? message : LoaderService._DEFAULT_MESSAGE;

      const dialogMessage = new LoaderMessage();
      dialogMessage.message = messageValue;
      dialogMessage.finished = !isLoading;
      this._order++;
      dialogMessage.order = this._order;

      this._mapRequest.set(requestId, dialogMessage);
      this._requestPending++;

      const activeSize = this._mapRequest.size;

      if (activeSize === 1) {
        // Se incluye el mensaje en el mapa de mensajes activos.
        this._logger.debug('[LOADER SERVICE] setLoading. Primera request');
        this._open(this._mapRequest);
      } else {
        // añade el mensaje de la request
        this._logger.debug('[LOADER SERVICE] setLoading. Se crea nueva peticion');
        if (this.dialogRef !== undefined) {
          this.dialogRef!.componentInstance.data.messages = this._mapRequest;
        }
      }
    } else {
      // marca la request como terminada
      this._logger.debug('[LOADER SERVICE] setLoading. Request finalizada ');
      if (this._mapRequest.has(requestId)) {
        const request = this._mapRequest.get(requestId);
        if (request !== undefined) {
          request.finished = true;
          this._requestPending--;
        }
      }
      if (this.dialogRef !== undefined && this.dialogRef.componentInstance != null) {
        this.dialogRef.componentInstance.data.messages = this._mapRequest;
      }
      if (this._requestPending === 0) {
        this._logger.debug('[LOADER SERVICE] setLoading. No hay mas request, se cierra el dialogo');
        this._order = 0;
        this._mapRequest = new Map();
        this.dialogRef?.close();
      }
    }
  }

  /**
   * Devuelve la cabecera de loader para los parámetros de entrada al dialogo.
   *
   * @param maxAge Indica cantidad de tiempo en segundos que la respuesta cacheada es válida
   * @param revalidate Indica si la petición se debe revalidar
   * @returns
   */
  getHeaderLoader(httpHeaders: HttpHeaders | undefined, loading: boolean | undefined, message?: string): HttpHeaders {
    let newHttHeaders = httpHeaders !== undefined ? httpHeaders : new HttpHeaders();
    const loadingValue = loading !== undefined ? loading.toString() : 'false';
    const messageValue = message !== undefined ? message : LoaderService._DEFAULT_MESSAGE;

    newHttHeaders = newHttHeaders.append(LoaderService._HEADER_LOADER, loadingValue);
    newHttHeaders = newHttHeaders.append(LoaderService._MESSAGE_LOADER, messageValue);
    return newHttHeaders;
  }
}
