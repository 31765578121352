// eslint-disable-next-line prettier/prettier
import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

import { MSECorreo, MSETelefono, MSSCorreo, MSSTelefono, Operacion } from '../model/api/models';

import { DigitalDataService } from './digital-data.service';
import { Constants } from '../others/constants';
import { TransitionService } from './transition.service';

/**
 * Servicio con las validaciones genericas de datos
 * Ejemplo de uso:
 *  1.- En la definicion de controles del formulario
 *      this.xxxxxForm = this._fb.group({
 *            email: ['', ValidatorService.email],
 *           numMovil: ['', ValidatorService.phoneNumber],
 *           numFijo: ['', ValidatorService.phoneNumber],
 *       });
 *  2.- En el template:
 *      <mat-error *ngIf="controls.numFijo.invalid">{{ validatorService.getError(controls.numFijo) | translate }}</mat-error>
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  /**
   * Logger  of oferta service
   */
  private _logger: NGXLogger;
  onLoadValidateEmail: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  onLoadValidatePhone: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  /**
   * Creates an instance of validator service.
   *
   * @param _customLogger
   * @param _environment
   */
  constructor(
    private _customLogger: CustomNGXLoggerService,
    private _apiService: ApiService,
    private _digitalDataService: DigitalDataService,
    private _transitionService: TransitionService
  ) {
    //logger con configuración propia para el modulo de oferta
    this._logger = this._customLogger.create(environment.logger!.services!);
    this._logger.info('[VALIDATOR SERVICE] Constructor');
  }

  /**
   * Recupera el mensaje de error para el control
   *
   * @param control
   */
  static getError(control: AbstractControl | null): string {
    if (control) {
      if (control.hasError('required')) {
        return 'validationService.required';
      }
      if (control.hasError('email')) {
        return 'validationService.email';
      }
      if (control.hasError('phone')) {
        return 'validationService.phone';
      }
      if (control.hasError('minimumAge')) {
        return 'validationService.minimumAge';
      }
      if (control.hasError('maximumAge')) {
        return 'validationService.maximumAge';
      }
      if (control.hasError('incorrectAmount')) {
        return 'validationService.incorrectAmount';
      }
    }
    return '';
  }

  /**
   * Comprueba si es un email valido
   *
   * @param control
   */
  static email(control: AbstractControl): { [key: string]: boolean } | null {
    const EXPRESION_EMAIL = new RegExp(
      // eslint-disable-next-line max-len
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (control.value && !EXPRESION_EMAIL.test(control.value)) {
      return { email: true };
    }
    return null;
  }

  /**
   * Comprueba si es un número de teléfono válido
   *
   * @param control
   */
  static phoneNumber(control: AbstractControl): { [key: string]: boolean } | null {
    const EXPRESION_PHONE = new RegExp(/^[0-9]{9}$/);
    if (control.value && !EXPRESION_PHONE.test(control.value)) {
      return { phone: true };
    }
    return null;
  }

  /**
   * Comprueba la edad minima
   *
   * @param control
   */
  ageRange(edad: number): { [key: string]: boolean } | null {
    if (edad < 18) return { minimumAge: true };
    else if (edad > 99) return { maximumAge: true };
  }

  static postalCode(control: AbstractControl): { [key: string]: boolean } | null {
    const EXPRESION_CP = new RegExp(
      /^(([1-4]\d)|(0[1-9])|(5[012]))(([1-7]{3})|(0\d[1-9])|(0[1-9]\d)|([1-9]\d0)|([1-9]0[1-9])|(9\d[1-7])|(9[0-8]\d)|([0-8]\d9)|([0-8]9[0-8])|([0-8][0-8][0-7])|([0-8][0-][0-8]))$/
    );
    if (!control.value) return { required: true };
    if (control.value && !EXPRESION_CP.test(control.value)) return { incorrectAmount: true };
  }

  validatePhoneNumber(phoneNumberP: string, operacionTransition: Operacion): BehaviorSubject<boolean> {
    const EXPRESION_PHONE = new RegExp(/^[0-9]{9}$/);

    if (EXPRESION_PHONE.test(phoneNumberP)) {
      const mSETelefono: MSETelefono = {
        numeroTelefono: phoneNumberP,
        operacion: operacionTransition
      };

      this._apiService.postValidatePhone(mSETelefono).subscribe(
        (output: { data: MSSTelefono }) => {
          if (output.data.validado) {
            this._transitionService.usuario.telMovilValido = true;
          } else {
            this._transitionService.usuario.telMovilValido = false;
          }
          this.onLoadValidatePhone.next(true);
        },
        error => {
          this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'validar telefono');
          this._logger.error('[ValidatorService] validatePhoneNumber: error del servicio ->', error);
          this._transitionService.usuario.telMovilValido = false;
          this.onLoadValidatePhone.next(true);
        }
      );
    } else {
      //pattern incorrecto
      this.onLoadValidatePhone.next(false);
    }

    return this.onLoadValidatePhone;
  }
  validateEmail(email: string, operacionTransition: Operacion): BehaviorSubject<boolean> {
    const EXPRESION_EMAIL = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (EXPRESION_EMAIL.test(email)) {
      const validateEmailRequest: MSECorreo = {
        correo: email,
        operacion: operacionTransition
      };

      this._apiService.postValidateEmail(validateEmailRequest).subscribe(
        (output: { data: MSSCorreo }) => {
          if (output.data.validado) {
            this._transitionService.usuario.emailValido = true;
          } else {
            this._transitionService.usuario.emailValido = false;
          }
          this.onLoadValidateEmail.next(true);
        },
        error => {
          this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'validar email');
          this._logger.error('[ValidatorService] validateEmail: error del servicio ->', error);
          this._transitionService.usuario.emailValido = false;
          this.onLoadValidateEmail.next(true);
        }
      );
    } else {
      //pattern incorrecto
      this.onLoadValidateEmail.next(false);
    }
    return this.onLoadValidateEmail;
  }
}
