import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Constants } from './others/constants';
registerLocaleData(localeES, Constants.SPANISH);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot(environment.logger.default)
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
