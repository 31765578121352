import { Pipe, PipeTransform } from '@angular/core';
import { formatDecimal, formatInteger } from '../others/utiles';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number, decimal: boolean = false): string {
    if (decimal) return formatDecimal(value);
    else return formatInteger(value);
  }
}
