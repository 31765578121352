import { Component, OnInit, HostListener } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { amountJubilacion, formatDecimal } from 'src/app/others/utiles';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bloque-seguridad-familiar',
  templateUrl: './bloque-seguridad-familiar.component.html',
  styleUrls: ['./bloque-seguridad-familiar.component.scss']
})
export class BloqueSeguridadFamiliarComponent implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  /**
   * Fomulario para la captura y validación de tienes ahorros
   */
  chartTypePie: ChartType = ChartType.PieChart;
  optionsPieChart: object;

  dataChart: (string | number)[][];

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _unsubscribeAll: Subject<any>;

  constructor(private _customLogger: CustomNGXLoggerService, private _transitionService: TransitionService) {
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[BloqueSeguridadFamiliarComponent] constructor');

    this._unsubscribeAll = new Subject();
    this.dataChart = [['', 100]];
    this.optionsPieChart = {
      pieHole: 0.3,
      backgroundColor: { fill: 'transparent' },
      theme: 'maximized',
      legend: 'none',
      slices: {
        0: { color: '#526570' }
      }
    };
  }

  ngOnInit(): void {
    /*El dataChart necesita ser cargado en el OnInit ya que si se toma los datos en funcion del transition
    genera un error porque pinta el chart antes de obtener los datos*/
    this._transitionService.onDatosTarificacionChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: boolean) => {
        if (data) {
          const cuantiaJubilacion = amountJubilacion(this._transitionService);
          this.dataChart = [
            [
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo + '€',
              this._transitionService.calculoOut.productoAhorroInversion.perfilArriesgado.perfil20.saldo
            ],
            [
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal + '€',
              this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal
            ],
            [cuantiaJubilacion + '€', cuantiaJubilacion]
          ];

          this._getScreenSize();
        }
      });
  }

  get capitalFallecimiento(): string {
    if (this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal !== undefined)
      return formatDecimal(this._transitionService.calculoOut.productoSeguridadFamiliar.capitalFinal);
    else return '';
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _getScreenSize(event?: undefined): void {
    this.optionsPieChart = {
      tooltip: { trigger: 'none' },
      pieHole: 0.5,
      legend: 'none',
      backgroundColor: { fill: 'transparent' },
      chartArea: { width: '100%', height: '100%' },
      pieSliceText: 'none',
      slices: {
        0: { color: '#9cb0bc' },
        1: { color: '#a51783' },
        2: { color: '#d7dfe4' }
      }
    };
  }
}
