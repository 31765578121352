import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { Constants } from 'src/app/others/constants';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tienes-ahorros',
  templateUrl: './tienes-ahorros.component.html',
  styleUrls: ['./tienes-ahorros.component.scss']
})
export class TienesAhorrosComponent extends StepComponentAbstract implements OnInit {
  /**
   * Logger  of datos basicos component
   */
  private _logger: NGXLogger;

  /**
   * Formulario para la tarificacion
   */
  // pricingForm: FormGroup;
  /**
   * Fomulario para la captura y validación de la aportación diaria
   */
  tienesAhorrosForm: FormGroup;

  constructor(
    private _customLogger: CustomNGXLoggerService,
    // private _ctrlContainer: FormGroupDirective,
    private _fb: FormBuilder,
    private _transitionService: TransitionService
  ) {
    super();
    //inicializamos el logger
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[TienesAhorrosComponent] constructor');

    //inicializamos el formulario
    this.tienesAhorrosForm = this._createForm();
  }

  /**
   * Inicializamos el formulario de los objetivos
   *
   * @returns objetivos form
   */
  private _createForm(): FormGroup {
    //creamos el formulario
    const form = this._fb.group({
      tienesAhorros: [Constants.VACIO, Validators.required]
    });
    return form;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    Promise.resolve().then(() => {
      this.control.setValue(this.tienesAhorros);
    });
  }

  isValid(): boolean {
    return this.tienesAhorrosForm.valid;
  }

  /**
   * Gets tarificador data
   */
  get tienesAhorros(): string {
    return this._transitionService.datosTarificacion.tienesAhorros ? 'true' : 'false';
  }

  /**
   * Sets tarificador data
   */
  set tienesAhorros(value: string) {
    let ahorro = false;
    if (value === 'true') ahorro = true;
    this._transitionService.datosTarificacion.tienesAhorros = ahorro;
  }

  /**
   * Acceso al único control del formulario
   */
  get control(): AbstractControl {
    return this.tienesAhorrosForm.controls.tienesAhorros;
  }
}
