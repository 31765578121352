import { NgxLoggerLevel } from 'ngx-logger';
import { ConfigEnvironment } from 'src/app/model/config.environment';
import { Constants } from 'src/app/others/constants';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: ConfigEnvironment = {
  production: false,
  enviroment: 'local',
  logger: {
    default: {
      level: NgxLoggerLevel.ERROR, // nivel de log de la aplicación
      //serverLoggingUrl: {string},
      //serverLogLevel: NgxLoggerLevel.ERROR,
      //disableConsoleLogging: {boolean},
      //httpResponseType: {'arraybuffer' | 'blob' | 'texto' | 'json'},
      enableSourceMaps: true
      //timestampFormat: { cadena },
      //colorScheme: ['violeta', 'verde azulado', 'gris', 'gris', 'rojo', 'rojo', 'rojo'],
      //disableFileDetails: {boolean},
      //proxiedSteps{número},
    },

    modules: {
      pricing: {
        level: NgxLoggerLevel.ERROR,
        //serverLoggingUrl: {string},
        //serverLogLevel: NgxLoggerLevel.ERROR,
        //disableConsoleLogging: {boolean},
        //httpResponseType: {'arraybuffer' | 'blob' | 'texto' | 'json'},
        enableSourceMaps: true
        //timestampFormat: { cadena },
        //colorScheme: ['violeta', 'verde azulado', 'gris', 'gris', 'rojo', 'rojo', 'rojo'],
        //disableFileDetails: {boolean},
        //proxiedSteps{número},
      },
      customization: {
        level: NgxLoggerLevel.ERROR
      },
      loader: {
        level: NgxLoggerLevel.ERROR
      },
      auth: {
        level: NgxLoggerLevel.ERROR
      },
      messages: {
        level: NgxLoggerLevel.ERROR
      },
      cache: {
        level: NgxLoggerLevel.ERROR
      }
    },
    services: {
      level: NgxLoggerLevel.ERROR
    }
},
  consent: {
    token: Constants.TOKEN_PRO,
    id_politica: Constants.ID_POLITICA_PRO,
    id_publicidad: Constants.ID_PUBLICIDAD_PRO,
    test: false,
  }
};
