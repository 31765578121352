import { Component, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { NextStep } from 'src/app/model/stepper/next-step';
import { StepComponentAbstract } from 'src/app/model/stepper/step-component.abstract';
import { TransitionService } from 'src/app/services/transition.service';
import { PricingStepDirective } from '../pricing-step.directive';

/**
 * Componente para la carga dinámica de cada uno de los pasos del proceso
 * de pricing / contratación
 */
@Component({
  selector: 'app-dynamic-step-loader',
  templateUrl: './dynamic-step-loader.component.html'
})
export class DynamicStepLoaderComponent implements OnInit {
  /**
   * View child of dynamic step loader component
   */
  @ViewChild(PricingStepDirective, { static: true })
  pricingStepDirective!: PricingStepDirective;

  /**
   * Creates an instance of dynamic step loader component.
   *
   * @param _componentFactoryResolver
   * @param _TransitionService
   */
  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _transitionService: TransitionService
  ) {}

  /**
   * on init
   */
  ngOnInit(): void {
    const nextStep: NextStep = this._transitionService.getNext();
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory<StepComponentAbstract>(
      nextStep.component
    );
    const viewContainerRef = this.pricingStepDirective.viewContainerRef;
    const componentRef = viewContainerRef.createComponent<StepComponentAbstract>(componentFactory);
    // Se informa la posicion del step
    componentRef.instance.stepIndex = nextStep.index;
    this._transitionService.instances[nextStep.index] = componentRef.instance;
  }
}
