import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { PricingModule } from './pricing/pricing.module';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FooterComponent } from './footer/footer.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { GlobalErrorHandlerService } from '../services/global-error-handler.service';
import { MsgModalComponent } from './msg-modal/msg.modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorComponent } from './error/error.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    MatDialogModule,
    DragDropModule,
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    PricingModule,
    BrowserModule,
    ReactiveFormsModule,
    DragDropModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatListModule,
    MatIconModule
  ],
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, LoaderComponent, MsgModalComponent, ErrorComponent],
  exports: [LayoutComponent, LoaderComponent, MsgModalComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class LayoutModule {}
