import { Component, QueryList, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { TransitionService } from 'src/app/services/transition.service';
import { pricingConfMatStep } from './pricing-conf-mat-step';
import { DynamicStepLoaderComponent } from './dynamic-step-loader/dynamic-step-loader.component';
import { MapperService } from 'src/app/services/mapper.service';
import { ApiService } from 'src/app/services/api.service';
import { MSERegistroOperacion, MSETarificacion } from 'src/app/model/api/models';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { Constants } from 'src/app/others/constants';
import { ErrorMessage } from 'src/app/model/message/error-message';

/**
 * Componente para el proceso de pricing / contratación
 * Contendrá la estructura del formuladio por pasos, así como las funcionalidades
 * para el control del formulario.
 */
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true }
    }
  ]
})
export class PricingComponent implements AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  /**
   * Lista de pasos creados dinamicamente
   */
  @ViewChildren(DynamicStepLoaderComponent) dynamicStepLoader!: QueryList<DynamicStepLoaderComponent>;
  /**
   * Estructura de pasos
   */
  @ViewChild('pricingStepper') pricingStepper!: MatStepper;
  /**
   * Logger  of pricing service
   */
  private _logger: NGXLogger;
  private _lockExpressionChangedError = true;

  pricingConfMatStep_ = pricingConfMatStep;
  totalSteppers: number = Constants.TOTAL_STEPPERS;

  lastPage: number;
  /**
   * Api service of pricing component
   */

  /**
   * Creates an instance of pricing component.
   *
   * @param _router
   * @param transitionService
   * @param _customLogger
   * @param _apiService
   * @param _mapperService
   * @param digitalDataService
   */
  constructor(
    public transitionService: TransitionService,
    private _customLogger: CustomNGXLoggerService,
    private _apiService: ApiService,
    private _mapperService: MapperService,
    private _digitalDataService: DigitalDataService,
    private _route: Router
  ) {
    //logger con configuración propia para el modulo de pricing
    this._logger = this._customLogger.create(environment.logger.modules!.pricing!);
    this._logger.info('[PricingComponent] constructor');
    //inicializamos el formulario
    this.transitionService.reset();
    this._unLockExpressionChangedError(this);
    if (this.transitionService.flags.isAportacionDiaria) this.totalSteppers--;
    //para llamar solo al ajuste de alto si cambia la página
    this.lastPage = -1;
  }

  /**
   * Timers acceleration
   *
   * @param obj
   */
  private _unLockExpressionChangedError(obj: PricingComponent) {
    setTimeout(function () {
      obj._lockExpressionChangedError = false;
    }, 1000);
  }

  get stepIndex(): number {
    return this.transitionService.initialPage;
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'none';
  }

  /**
   * Función de marcha atrás en los pasos
   */
  goBack(): void {
    if (this.pricingStepper.selectedIndex === 6 && !this.transitionService.datosTarificacion.tienesAhorros) {
      this.pricingStepper.selectedIndex = 2;
    } else {
      // en caso contrario vamos al paso anterior
      this.pricingStepper.previous();
    }
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_BACK);
    this.transitionService.page = this.pricingStepper.selectedIndex;
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
  }

  /**
   * Funcion para avanzar al paso siguiente
   */
  continue(): void {
    this._logger.info('[PricingComponent] continue:', JSON.stringify(this.transitionService.datosTarificacion));
    //if (this.transitionService.instances[this.pricingStepper.selectedIndex].continue()) {
    // si no es el último paso, vamos al siguiente

    if (this.transitionService.lastEditedStep < this.pricingStepper.selectedIndex)
      this.transitionService.lastEditedStep = this.pricingStepper.selectedIndex;

    this.pricingStepper.next();
    this._stepTienesAhorros(this.pricingStepper.selectedIndex);
    this._sendOperacion(this.pricingStepper.selectedIndex);
    this._sendTaficacion(this.pricingStepper.selectedIndex);
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_FIRST_ITERATION);
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_CONTINUE);
    this.transitionService.page = this.pricingStepper.selectedIndex;
    this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_STEP);
  }

  /**
   * Determina si el paso actual es válido. Es usado para marcar un paso como
   * completado
   *
   * @returns true if valid
   */
  isValid(): boolean {
    if (!this._lockExpressionChangedError && this.pricingStepper && this.pricingStepper.selectedIndex !== undefined) {
      return this.transitionService.instances[this.pricingStepper.selectedIndex].isValid();
    }
    return false;
  }

  /**
   * Computes step status classes
   *
   * @returns step status classes
   */
  computeDivStepClasses(): string {
    let computeClass = 'stepper';
    if (!this._lockExpressionChangedError) {
      if (this.pricingStepper && this.pricingStepper.selectedIndex > 1) {
        computeClass += ' heightFull';
      }
    }
    return computeClass;
  }
  /**
   * Computes step status classes
   *
   * @returns step status classes
   */
  computeStepStatusClasses(): string {
    let computeClass = ' last-edited-step-' + (this.transitionService.lastEditedStep + 1);
    if (this.transitionService.flags.isAportacionDiaria) computeClass += ' isAportacionDiaria ';
    if (!this._lockExpressionChangedError) {
      if (this.pricingStepper && this.pricingStepper.selectedIndex !== undefined) {
        if (this.pricingStepper) computeClass += ' current-edited-step-' + (this.pricingStepper.selectedIndex + 1);
        else computeClass += ' current-edited-step-1';
        if (this.pricingStepper.selectedIndex !== 1 && this.pricingStepper.selectedIndex !== 7)
          this.transitionService.flags.isFullHeightMobile = true;
        else this.transitionService.flags.isFullHeightMobile = false;
        if (this.pricingStepper.selectedIndex !== 7) this.transitionService.flags.isFullHeight = true;
        else this.transitionService.flags.isFullHeight = false;
        if (this.lastPage !== this.transitionService.page) {
          this.lastPage = this.transitionService.page;
          this.transitionService.onStepChanged.next(true);
        }
      }
    }
    return computeClass;
  }

  private _stepTienesAhorros(selectedIndex: number) {
    //Si no se declara de esta manera no fucniona correctamente la condicion
    const tienesAhorros: boolean = this.transitionService.datosTarificacion.tienesAhorros;
    if (selectedIndex === 3 && !tienesAhorros) {
      this.pricingStepper.selectedIndex = 6;
      if (this.transitionService.lastEditedStep < this.pricingStepper.selectedIndex - 1)
        this.transitionService.lastEditedStep = this.pricingStepper.selectedIndex - 1;
      this.transitionService.datosTarificacion.ahorroCuentasBancarias = 0;
      this.transitionService.datosTarificacion.ahorroInversion = 0;
      this.transitionService.datosTarificacion.ahorroJubilacion = 0;
    }
  }

  private _sendOperacion(selectedIndex: number) {
    this._logger.info('[pricingComponent] _sendOperacion');
    let mSERegistroOperacion: MSERegistroOperacion;
    let envioOperacion = false;
    try {
      // Mensajes de operacion inicial origen ICT
      if (selectedIndex === 1 && this.transitionService.oficina !== undefined) {
        mSERegistroOperacion = this._mapperService.mapOperacionICT(
          this.transitionService.operacion,
          this.transitionService.oficina,
          this.transitionService.datosTarificacion
        );
        envioOperacion = true;
      }
      // Mensajes de operacion inicial origen PUBLICO
      else if (selectedIndex === 2 && this.transitionService.oficina === undefined) {
        mSERegistroOperacion = this._mapperService.mapOperacionPortaPublico(
          this.transitionService.operacion,
          this.transitionService.datosTarificacion
        );
        envioOperacion = true;
      }

      if (envioOperacion) {
        //No se espera respuesta del registro de oeracion
        this._apiService.postRegistroOperacion(mSERegistroOperacion).subscribe(
          () => {},
          error => {
            this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'registro operacion');
            this._logger.error('[pricingComponent] _sendOperacion: error del servicio ->', error);
          }
        );
      }
    } catch (error) {
      this._logger.error('[pricingComponent] _sendOperacion: error del cliente ->', error);
    }
  }

  private _sendTaficacion(selectedIndex: number) {
    this._logger.info('[pricingComponent] _sendTaficacion');

    if (selectedIndex === 7) {
      this.transitionService.cantidadAhorro =
        this.transitionService.datosTarificacion.ahorroCuentasBancarias +
        this.transitionService.datosTarificacion.ahorroInversion +
        this.transitionService.datosTarificacion.ahorroJubilacion;

      const mSEtarificacion: MSETarificacion = {};
      mSEtarificacion.calculoIn = this.transitionService.datosTarificacion;
      mSEtarificacion.oficina = this.transitionService.oficina;
      mSEtarificacion.usuario = this.transitionService.usuario;
      mSEtarificacion.forceFlow = this.transitionService.forceFlow;
      mSEtarificacion.operacion = this._mapperService.mapOperacionServer(this.transitionService.operacion);

      this.transitionService.flags.isFullHeight = true;
      this.transitionService.onStepChanged.next(true);
      this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_SIMULATION);

      this._apiService.postTarificacion(mSEtarificacion).subscribe(
        data => {
          this.transitionService.calculoOut = data.data.calculoOut;
          this.transitionService.flags.isCargaWallmeric = false;
          this.transitionService.onDatosTarificacionChanged.next(true);
        },
        error => {
          this._digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_ERROR, '500', error, 'simulacion');
          this.transitionService.flags.isFullHeight = false;
          this.transitionService.onStepChanged.next(true);
          this._route.navigateByUrl(`/calculadora-ahorro/blank${this.transitionService.urlParam}`);
          throw new ErrorMessage(error);
        }
      );
    }
  }

  /**
   * init step completed
   *
   * @param i
   * @returns
   */
  initStepCompleted(i: number): boolean {
    //en modo consulta los step están completados hasta el de proyecto
    return (
      !this._lockExpressionChangedError &&
      this.transitionService.instances.length > i &&
      this.transitionService.instances[i].isValid()
    );
  }

  spinnerProgresLabel(index: number) {
    let ret;
    switch (index) {
      case undefined:
      case 0:
        ret = 1;
        break;
      case 1:
        ret = 2;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        ret = 3;
        break;
      case 6:
        ret = 4;
        break;
      default:
        ret = 5;
        break;
    }
    if (this.transitionService.flags.isAportacionDiaria) ret--;
    return ret.toString();
  }

  spinnerProgres(index: number) {
    if (this.transitionService.flags.isAportacionDiaria)
      switch (index) {
        case undefined:
        case 0:
        case 1:
          return 25;
        case 2:
        case 3:
        case 4:
        case 5:
          return 50;
        case 6:
          return 75;
        default:
          return 100;
      }
    else
      switch (index) {
        case undefined:
        case 0:
          return 20;
        case 1:
          return 40;
        case 2:
        case 3:
        case 4:
        case 5:
          return 60;
        case 6:
          return 80;
        default:
          return 100;
      }
  }
}
