import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';
import { Constants } from 'src/app/others/constants';
import { DigitalDataService } from 'src/app/services/digital-data.service';
import { TransitionService } from 'src/app/services/transition.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  /**
   * Logger  of vehiculos dialog component
   */
  private _logger: NGXLogger;
  /**
   * Type message of msg modal snack component. Required for HTML
   */
  Constants: typeof Constants;

  constructor(
    public digitalDataService: DigitalDataService,
    private _customLogger: CustomNGXLoggerService,
    private _transitionService: TransitionService,
    private _route: Router,
    private _ngZone: NgZone
  ) {
    this._logger = this._customLogger.create(environment.logger.modules!.messages);
    this._logger.info('[MSGMODAL COMPONENT]: constructor');

    this._transitionService.flags.isCargaWallmeric = true;

    this.Constants = Constants;
  }

  get tuPlanVisible(): boolean {
    return this._transitionService.flags.isMostrarTeLlamamos && this._transitionService.flags.isCargaWallmeric;
  }

  close() {
    this._transitionService.initialPage = Constants.PAGE_APORTACION_DIARIA;
    this._transitionService.flags.isAportacionDiaria = false;
    this._transitionService.page = this._transitionService.initialPage;
    this._transitionService.lastEditedStep = -1;
    this._transitionService.onLoadTranslation.next(true);
    this.digitalDataService.sendDigitalData(Constants.DIGITAL_DATA_EVENT_ACCEPT);
    this._ngZone.run(() => {
      this._route.navigateByUrl(`/calculadora-ahorro/simulador${this._transitionService.urlParam}`);
    });
  }
}
