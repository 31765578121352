import { Injectable } from '@angular/core';
import { Consent, DatosCumplimentados, MSERegistroOperacion } from '../model/api/models';
import { Operacion } from '../model/api/operacion';
import { Oficina } from '../model/api/oficina';
import { Constants } from 'src/app/others/constants';
import { ConsentConfigEnvironment } from '../model/config.environment';

@Injectable({
  providedIn: 'root'
})
export class MapperService {
  public mapOperacion(origen: string): Operacion {
    const operacion: Operacion = {};

    operacion.origen = origen;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
    ) {
      operacion.acceso = '7';
    } else {
      operacion.acceso = '5';
    }

    return operacion;
  }

  public mapOperacionICT(
    operacion: Operacion,
    oficina: Oficina,
    datosCumplimentados: DatosCumplimentados
  ): MSERegistroOperacion {
    const mSERegistroOperacion: MSERegistroOperacion = { operacion };

    mSERegistroOperacion.operacion.clave = '14';
    mSERegistroOperacion.operacion.mensaje = `Origen OFICINA. NUUMA: (${oficina.agente}) - Aportación: (${datosCumplimentados.aportacionDiaria})`;

    return mSERegistroOperacion;
  }

  public mapOperacionPortaPublico(
    operacion: Operacion,
    datosCumplimentados: DatosCumplimentados
  ): MSERegistroOperacion {
    const mSERegistroOperacion: MSERegistroOperacion = { operacion };

    mSERegistroOperacion.operacion.clave = '14';
    mSERegistroOperacion.operacion.mensaje = `Origen PORTAL PUBLICO: Aportacion: (${datosCumplimentados.aportacionDiaria})`;

    return mSERegistroOperacion;
  }

  public mapOperacionEstado(estado: number, operacion: Operacion): Operacion {
    switch (estado) {
      case Constants.TITLE_CONTACTO_PDF:
        operacion.estado = 'Descarga resultados en PDF';
        break;
      case Constants.TITLE_CONTACTO_TE_LLAMAMOS:
        operacion.estado = 'Te llamamos gratis';
        break;
      case Constants.TITLE_CONTACTO_ME_INTERESA:
        operacion.estado = 'Me interesa';
        break;
      default:
        operacion.estado = 'Error estado';
    }

    return operacion;
  }

  /**
   * Para las operaciones que se calculan en servidor no es necesaria la clave ni el mensaje, pero si los datos guardados en el acceso y origen
   *
   * @param operacion
   * @returns
   */
  public mapOperacionServer(operacion: Operacion): Operacion {
    operacion.clave = null;
    operacion.mensaje = null;

    return operacion;
  }

  /**
   * Crea el objeto de consentimiento
   */
  public mapConsetimiento(publicidad: boolean, consentBlock: ConsentConfigEnvironment): Consent {
    const consent: Consent = {};

    consent.informationToken = consentBlock.token;
    consent.purposePoliticaId = consentBlock.id_politica;
    if (publicidad !== null && publicidad) {
      consent.purposePublicityId = consentBlock.id_publicidad;
    } else {
      consent.purposePublicityId = null;
    }

    consent.registryIsTest = consentBlock.test;

    return consent;
  }
}
