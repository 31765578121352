import { TransitionService } from '../services/transition.service';
import { Constants } from './constants';

export function formatDecimal(_number: number): string {
  return fixOnly4(new Intl.NumberFormat(Constants.SPANISH, Constants.FORMAT_DECIMAL).format(_number));
}

export function formatInteger(_number: number): string {
  return fixOnly4(new Intl.NumberFormat(Constants.SPANISH, Constants.FORMAT_INT).format(_number));
}

function fixOnly4(text: string): string {
  const indexComa = text.indexOf(',');
  if (indexComa === 4 || (indexComa === -1 && text.length === 4)) {
    text = text[0] + '.' + text.slice(1);
  }
  return text;
}

export function amountJubilacion(transitionService: TransitionService): number {
  let amount = 0;
  if (transitionService.calculoOut.productoJubilacion.perfilArriesgado.saldo !== undefined) {
    amount = transitionService.calculoOut.productoJubilacion.perfilArriesgado.saldo;
  } else {
    amount = transitionService.datosTarificacion.ahorroJubilacion;
  }
  return amount;
}
